<script setup>
import md5 from 'md5';
import { computed, ref } from "vue";

const props = defineProps({
  userName: String,
  userEmail: String,
});

const gravatarUrl = (email) => {
  const hash = md5(email);
  return `https://www.gravatar.com/avatar/${hash}?d=404`;
};

const initials = computed(() => getInitials(props.userName));

const showImage = ref(true);

function getInitials(name) {
  const names = name.split(" ");
  if (names.length >= 2) {
    const firstInitial = names[0].charAt(0).toUpperCase();
    const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  } else if (names.length === 1) {
    return names[0].charAt(0).toUpperCase();
  }
  return "";
}

function handleImageError() {
  showImage.value = false;
}
</script>

<template>
  <img
    v-if="showImage"
    :src="gravatarUrl(props.userEmail)"
    @error="handleImageError"
    class="h-10 w-10 rounded-full bg-gray-50"
  />
  <div
    class="bg-cyan-100 text-cyan-700 flex items-center justify-center font-semibold text-lg rounded-full h-10 w-10"
    v-else
  >
    {{ initials }}
  </div>
</template>
