<script setup>
import { ref, computed, onMounted } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { getLoggedUser, onlyNumbers } from "@/utils";
import axios from "@/axios";
import defaultAxios from "axios";
import { format } from "v-money3";
import { useToast } from "vue-toastification";

import { UiLoading } from "@/components";
import { UiInputText } from "@/components";

const route = useRoute();
const proposta = ref();
const toast = useToast();

const loading = ref(false);

const formData = ref({
  company: {
    document: "",
    name: "",
    zipcode: "",
    address: "",
    number: "",
    complemento: "",
    neighborhood: "",
    city: "",
    uf: "",
    phone: "",
    whatsapp: "",
    email: "",
  },
  signing: {
    name: "",
    ocupation: "",
    phone: "",
    email: "",
  },
  services: Array.from({ length: 1 }, () => ({
    id: null,
    qtd: 0,
    vUnit: 0,
    vTotal: 0,
  })),
  setups: Array.from({ length: 1 }, () => ({
    name: "",
    qtd: 0,
    vUnit: 0,
    vTotal: 0,
  })),
  paymentCoditions: {
    contractTime: "",
    monthValue: 0,
    setupValue: 0,
    totalValue: 0,
    paymentStartDay: "",
    paymentMethod: "",
  },
  accountable: null,
  comment: "",
  specialConditions: "",
  minutagem: {
    fixo: null,
    movel: null,
    fixo_iternacional: null,
    movel_iternacional: null,
    gratuito_fixo: null,
    gratuito_movel: null,
    discador_fixo: null,
    discador_movel: null,
    numero_movel: null,
  },
});

const getDataOs = async (id) => {
  try {
    loading.value = !loading.value;

    const response = await axios({
      method: "GET",
      url: "/proposta?proposta_id=" + id,
    });

    proposta.value = response.data;

    fillDataOs(response.data);

    loading.value = !loading.value;
  } catch (error) {
    console.error(error);

    loading.value = !loading.value;

    router.push({ name: "PageCreateOs" });
  }
};

const fillDataOs = (data) => {
  formData.value.company.document = onlyNumbers(data.company_document) || null;
  formData.value.company.name = data.company_name || null;
  formData.value.company.zipcode = onlyNumbers(data.zipcode) || null;
  formData.value.company.address = data.address || null;
  formData.value.company.number = data.number || null;
  formData.value.company.complemento = data.complemento || null;
  formData.value.company.neighborhood = data.neighborhood || null;
  formData.value.company.city = data.city || null;
  formData.value.company.uf = data.uf || null;
  formData.value.company.phone = onlyNumbers(data.company_phone) || null;
  formData.value.company.whatsapp = onlyNumbers(data.company_whatsapp) || null;
  formData.value.company.email = data.company_email || null;

  formData.value.signing.name = data.signing_name || null;
  formData.value.signing.ocupation = data.signing_ocupation || null;
  formData.value.signing.phone = onlyNumbers(data.signing_phone) || null;
  formData.value.signing.email = data.signing_email || null;

  formData.value.accountable = data.id_user || null;
};

const createOS = async () => {
  const config = {
    method: "POST",
    url: "/create",
    data: formData.value,
  };

  try {
    for (const i in formData.value.minutagem) {
      const valor = formData.value.minutagem[i];
      if (valor !== null) {
        formData.value.minutagem[i] = onlyNumbers(valor);
      }
    }
    await axios(config);

    toast.success("Ordem de serviço criada com sucesso!", {
      timeout: 3000,
    });

    router.push({ name: "PageListOs" });
  } catch (error) {
    console.error(error);

    toast.error("Verifique se todos os campos foram preenchidos corretamente.", {
      timeout: 3000,
    });
  }
};

const services = ref([]);
const getServices = async () => {
  loading.value = !loading.value;

  try {
    const response = await axios({
      method: "GET",
      url: "/services",
    });

    services.value = response.data.rows.filter((r) => r.status === 1);
    loading.value = !loading.value;
  } catch (error) {
    console.error(error);
  }
};

const users = ref([]);
const getUsers = async () => {
  loading.value = !loading.value;

  try {
    const response = await axios({
      method: "GET",
      url: "/users",
    });

    users.value = response.data.rows;
    loading.value = !loading.value;
  } catch (error) {
    console.error(error);
  }
};

const getDoc = async (document) => {
  const doc = document.replace(/[^0-9]/g, "");

  const config = {
    method: "get",
    url: "https://api.cnpja.com/office/" + doc,
    headers: {
      Authorization: "54123371-5ee7-41c6-bab5-cc8030720b2a-f5b87f8f-7080-46af-97e5-f03b2c79e50f",
    },
  };

  if (doc.length === 14) {
    try {
      loading.value = !loading.value;
      const response = await defaultAxios(config);
      formData.value.company.name = response.data.company.name;
      loading.value = !loading.value;
    } catch (error) {
      formData.value.company.name = "";
      loading.value = !loading.value;
      console.error(error);
    }
  }
};

const getAddress = async (cep) => {
  const zipcode = cep.replace(/[^0-9]/g, "");

  const config = {
    method: "get",
    url: `https://viacep.com.br/ws/${zipcode}/json/`,
  };

  if (zipcode.length === 8) {
    try {
      loading.value = !loading.value;
      const response = await defaultAxios(config);
      formData.value.company.address = response.data.logradouro;
      formData.value.company.neighborhood = response.data.bairro;
      formData.value.company.city = response.data.localidade;
      formData.value.company.uf = response.data.uf;
      loading.value = !loading.value;
    } catch (error) {
      loading.value = !loading.value;
      console.error(error);
    }
  }
};

const money = computed(() => ({
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: "",
  precision: 2,
  masked: false,
  focusOnRight: true,
}));

const moneyString = {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: "",
  precision: 2,
  masked: false,
};

const paymentDays = [5, 10, 15, 20, 25];

const rangeContract = ["3 meses", "6 meses", "9 meses", "12 meses", "24 meses", "36 meses"];

const paymentMethod = ["Boleto", "Cartão"];

const validaMinutagem = (array1, array2) => {
  return array1.some((value) => array2.includes(value));
};

const minutagem = ref({
  fixoMovel: false,
  fixoMovelIternacional: false,
  gratuitoFixoMovel: false,
  discadorFixoMovel: false,
  numeroMovel: false,
});

const servicesSelecteds = ref([]);
const serviceSelected = (index, service) => {
  servicesSelecteds.value = formData.value.services
    .map((objeto) => objeto.id)
    .filter((element) => element !== null);

  minutagem.value.fixoMovel = validaMinutagem(servicesSelecteds.value, [1, 3, 5, 22]);
  if (!minutagem.value.fixoMovel) {
    formData.value.minutagem.fixo = null;
    formData.value.minutagem.movel = null;
  }

  minutagem.value.fixoMovelIternacional = validaMinutagem(servicesSelecteds.value, [2, 4]);
  if (!minutagem.value.fixoMovelIternacional) {
    formData.value.minutagem.fixo_iternacional = null;
    formData.value.minutagem.movel_iternacional = null;
  }

  minutagem.value.gratuitoFixoMovel = validaMinutagem(servicesSelecteds.value, [6]);
  if (!minutagem.value.gratuitoFixoMovel) {
    formData.value.minutagem.gratuito_fixo = null;
    formData.value.minutagem.gratuito_movel = null;
  }

  minutagem.value.discadorFixoMovel = validaMinutagem(servicesSelecteds.value, [7]);
  if (!minutagem.value.discadorFixoMovel) {
    formData.value.minutagem.discador_fixo = null;
    formData.value.minutagem.discador_movel = null;
  }

  minutagem.value.numeroMovel = validaMinutagem(servicesSelecteds.value, [23]);
  if (!minutagem.value.numeroMovel) {
    formData.value.minutagem.numero_movel = null;
  }
};

const totalGeral = ref(0);
const totalServices = ref(0);
const calcPriceService = (index, service) => {
  if (service.vUnit !== 0 && service.vUnit !== "") {
    formData.value.services[index].vTotal = service.qtd * service.vUnit.replace(/[^0-9]/g, "");
    totalServices.value = formData.value.services.reduce((acc, item) => acc + item.vTotal, 0);
    formData.value.paymentCoditions.monthValue = totalServices.value;
    totalGeral.value = totalServices.value + totalSetups.value;
    calcPriceContract();
  }
};

const totalSetups = ref(0);
const calcPriceSetup = (index, setup) => {
  if (setup.vUnit !== 0 && setup.vUnit !== "") {
    formData.value.setups[index].vTotal = setup.qtd * setup.vUnit.replace(/[^0-9]/g, "");
    totalSetups.value = formData.value.setups.reduce((acc, item) => acc + item.vTotal, 0);
    totalGeral.value = totalServices.value + totalSetups.value;
    formData.value.paymentCoditions.setupValue = totalSetups.value;
    calcPriceContract();
  }
};

const calcPriceContract = () => {
  const meses = parseInt(formData.value.paymentCoditions.contractTime);
  const fee = formData.value.paymentCoditions.monthValue;
  const setup = formData.value.paymentCoditions.setupValue;
  formData.value.paymentCoditions.totalValue = meses * fee + setup;
};

const adicionarServico = () => {
  formData.value.services.push({
    id: null,
    qtd: 0,
    vUnit: 0,
    vTotal: 0,
  });
};

const removerServico = (index) => {
  formData.value.services.splice(index, 1);
};

const adicionarSetup = () => {
  formData.value.setups.push({
    name: "",
    qtd: 0,
    vUnit: 0,
    vTotal: 0,
  });
};

const removerSetup = (index) => {
  formData.value.setups.splice(index, 1);
};

async function main() {
  try {
    await getServices();
    await getUsers();
    if (route.params.id) {
      await getDataOs(route.params.id);
    } else {
      await getLoggedUser(users.value, formData.value);
    }
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  main();
});
</script>

<template>
  <div>
    <h1 class="font-semibold text-xl text-gray-900">{{ route.meta.title }}</h1>
  </div>

  <div class="mt-8 pb-5 border-b-2 border-gray-200">
    <h3 class="font-semibold text-lg text-gray-700 mb-4">Dados da Empresa</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
      <UiInputText
        v-model="formData.company.document"
        name="document"
        label="CNPJ/CPF"
        v-mask="['###.###.###-##', '##.###.###/####-##']"
        @change="getDoc(formData.company.document)"
      />
      <UiInputText v-model="formData.company.name" name="name" label="Razão Social" />
      <UiInputText
        v-model="formData.company.zipcode"
        name="zipcode"
        label="CEP"
        v-mask="'#####-###'"
        @keyup="getAddress(formData.company.zipcode)"
      />
      <UiInputText v-model="formData.company.address" name="address" label="Endereço" />
      <UiInputText v-model="formData.company.number" name="number" label="Número" />
      <UiInputText v-model="formData.company.complemento" name="complemento" label="Complemento" />
      <UiInputText v-model="formData.company.neighborhood" name="neighborhood" label="Bairro" />
      <UiInputText v-model="formData.company.city" name="city" label="Cidade" />
      <UiInputText v-model="formData.company.uf" name="uf" label="UF" />
      <div>
        <label class="block font-medium text-gray-600 mb-1"> Telefone </label>
        <input
          v-model="formData.company.phone"
          type="text"
          name="phone"
          label="Telefone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 px-3 py-2"
        />
      </div>
      <div>
        <label class="block font-medium text-gray-600 mb-1"> WhatsApp </label>
        <input
          v-model="formData.company.whatsapp"
          type="text"
          name="phone"
          label="Telefone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 px-3 py-2"
        />
      </div>
      <UiInputText v-model="formData.company.email" name="email" label="E-mail" />
    </div>
  </div>

  <div class="mt-5 pb-5 border-b-2 border-gray-200">
    <h3 class="font-semibold text-lg text-gray-700 mb-4">Representantes Autorizados do Cliente</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
      <UiInputText v-model="formData.signing.name" name="name" label="Nome Completo" />
      <UiInputText v-model="formData.signing.ocupation" name="ocupation" label="Função" />
      <div>
        <label class="block font-medium text-gray-600 mb-1"> Telefone </label>
        <input
          v-model="formData.signing.phone"
          type="text"
          name="phone"
          label="Telefone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 px-3 py-2"
        />
      </div>
      <UiInputText v-model="formData.signing.email" name="email" label="E-mail" />
    </div>
  </div>

  <div class="mt-5 pb-5 border-b-2 border-gray-200">
    <h3 class="font-semibold text-lg text-gray-700 mb-4">Detalhes da Ordem</h3>

    <div class="flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Serviços
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Quantidade
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Valor unitário
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Valor total
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(service, index) in formData.services" :key="index">
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    <select
                      v-model="service.id"
                      @change="serviceSelected(index, service)"
                      class="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-mob-600 sm:text-sm sm:leading-6"
                    >
                      <option selected="true" :value="null">Escolha um serviço</option>
                      <option v-for="i in services" :key="i.id" :value="i.id">
                        {{ i.name }}
                      </option>
                    </select>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <UiInputText
                      v-model="service.qtd"
                      type="number"
                      id="qtd"
                      name="qtd"
                      :label="false"
                      @click="calcPriceService(index, service)"
                    />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <input
                      v-model.lazy="service.vUnit"
                      type="text"
                      name="vUnit"
                      id="vUnit"
                      v-money3="money"
                      @keyup="calcPriceService(index, service)"
                      class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                    />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ format(service.vTotal / 100, moneyString) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <div class="flex items-center p-2 space-x-3">
                      <button
                        @click="adicionarServico"
                        class="bg-gray-100 hover:bg-green-100 hover:text-gray-900 rounded px-1.5 shadow-sm text-sm text-gray-700 ring-1 ring-black ring-opacity-5"
                      >
                        <i class="bx bx-folder-plus"></i> Adicionar
                      </button>
                      <button
                        v-if="formData.services.length > 1"
                        @click="removerServico"
                        class="bg-gray-100 hover:bg-red-100 hover:text-gray-900 rounded px-1.5 shadow-sm text-sm text-gray-700 ring-1 ring-black ring-opacity-5"
                      >
                        <i class="bx bx-folder-minus"></i> Remover
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot class="bg-mob-50/50">
                <tr>
                  <td colspan="4" class="px-6 py-3.5 text-sm uppercase font-semibold">
                    <div class="float-right flex items-center">
                      <span class="display-inline">Valor Total de Serviços</span>
                      <div class="text-gray-900 sm:text-sm sm:leading-6 text-right w-32">
                        {{ format(totalServices / 100, moneyString) }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="flow-root mt-5">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <h3
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 bg-gray-50 border-b border-gray-300"
            >
              Minutagem
            </h3>
            <div class="grid grid-cols-4 gap-2 px-3 pb-3 bg-white">
              <div class="whitespace-nowrap px-3 py-2 font-medium text-gray-900">
                <p class="block font-medium text-gray-600 mb-1">Fixo</p>
                <input
                  v-if="minutagem.fixoMovel"
                  v-model="formData.minutagem.fixo"
                  type="text"
                  name="fixo"
                  id="fixo"
                  v-money3="money"
                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                />
                <p v-else class="px-3 py-1.5">---</p>
              </div>
              <div class="whitespace-nowrap px-3 py-2 font-medium text-gray-900">
                <p class="block font-medium text-gray-600 mb-1">Móvel</p>
                <input
                  v-if="minutagem.fixoMovel"
                  v-model="formData.minutagem.movel"
                  type="text"
                  name="fixo"
                  id="fixo"
                  v-money3="money"
                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                />
                <p v-else class="px-3 py-1.5">---</p>
              </div>
              <div class="whitespace-nowrap px-3 py-2 font-medium text-gray-900">
                <p class="block font-medium text-gray-600 mb-1">Fixo Internacional</p>
                <input
                  v-if="minutagem.fixoMovelIternacional"
                  v-model="formData.minutagem.fixo_iternacional"
                  type="text"
                  name="fixo"
                  id="fixo"
                  v-money3="money"
                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                />
                <p v-else class="px-3 py-1.5">---</p>
              </div>
              <div class="whitespace-nowrap px-3 py-2 font-medium text-gray-900">
                <p class="block font-medium text-gray-600 mb-1">Móvel Internacional</p>
                <input
                  v-if="minutagem.fixoMovelIternacional"
                  v-model="formData.minutagem.movel_iternacional"
                  type="text"
                  name="fixo"
                  id="fixo"
                  v-money3="money"
                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                />
                <p v-else class="px-3 py-1.5">---</p>
              </div>
              <div class="whitespace-nowrap px-3 py-2 font-medium text-gray-900">
                <p class="block font-medium text-gray-600 mb-1">0800/400X Fixo</p>
                <input
                  v-if="minutagem.gratuitoFixoMovel"
                  v-model="formData.minutagem.gratuito_fixo"
                  type="text"
                  name="fixo"
                  id="fixo"
                  v-money3="money"
                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                />
                <p v-else class="px-3 py-1.5">---</p>
              </div>
              <div class="whitespace-nowrap px-3 py-2 font-medium text-gray-900">
                <p class="block font-medium text-gray-600 mb-1">0800/400X Móvel</p>
                <input
                  v-if="minutagem.gratuitoFixoMovel"
                  v-model="formData.minutagem.gratuito_movel"
                  type="text"
                  name="fixo"
                  id="fixo"
                  v-money3="money"
                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                />
                <p v-else class="px-3 py-1.5">---</p>
              </div>
              <div class="whitespace-nowrap px-3 py-2 font-medium text-gray-900">
                <p class="block font-medium text-gray-600 mb-1">Discador Fixo</p>
                <input
                  v-if="minutagem.discadorFixoMovel"
                  v-model="formData.minutagem.discador_fixo"
                  type="text"
                  name="fixo"
                  id="fixo"
                  v-money3="money"
                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                />
                <p v-else class="px-3 py-1.5">---</p>
              </div>
              <div class="whitespace-nowrap px-3 py-2 font-medium text-gray-900">
                <p class="block font-medium text-gray-600 mb-1">Discador Móvel</p>
                <input
                  v-if="minutagem.discadorFixoMovel"
                  v-model="formData.minutagem.discador_movel"
                  type="text"
                  name="fixo"
                  id="fixo"
                  v-money3="money"
                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                />
                <p v-else class="px-3 py-1.5">---</p>
              </div>
              <div class="whitespace-nowrap px-3 py-2 font-medium text-gray-900">
                <p class="block font-medium text-gray-600 mb-1">Número Móvel</p>
                <input
                  v-if="minutagem.numeroMovel"
                  v-model="formData.minutagem.numero_movel"
                  type="text"
                  name="fixo"
                  id="fixo"
                  v-money3="money"
                  class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                />
                <p v-else class="px-3 py-1.5">---</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flow-root mt-5">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Produtos / Setup
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Quantidade
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Valor unitário
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Valor total
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(setup, index) in formData.setups" :key="index">
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    <UiInputText
                      v-model="setup.name"
                      type="text"
                      id="name"
                      name="name"
                      :label="false"
                    />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <UiInputText
                      v-model="setup.qtd"
                      type="number"
                      id="qtd"
                      name="qtd"
                      :label="false"
                      @click="calcPriceSetup(index, setup)"
                    />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <input
                      v-model.lazy="setup.vUnit"
                      type="text"
                      name="vUnit"
                      id="vUnit"
                      v-money3="money"
                      @keyup="calcPriceSetup(index, setup)"
                      class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 sm:text-sm sm:leading-6 px-3 py-1.5"
                    />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ format(setup.vTotal / 100, moneyString) }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <div class="flex items-center p-2 space-x-3">
                      <button
                        @click="adicionarSetup"
                        class="bg-gray-100 hover:bg-green-100 hover:text-gray-900 rounded px-1.5 shadow-sm text-sm text-gray-700 ring-1 ring-black ring-opacity-5"
                      >
                        <i class="bx bx-folder-plus"></i> Adicionar
                      </button>
                      <button
                        v-if="formData.setups.length > 1"
                        @click="removerSetup"
                        class="bg-gray-100 hover:bg-red-100 hover:text-gray-900 rounded px-1.5 shadow-sm text-sm text-gray-700 ring-1 ring-black ring-opacity-5"
                      >
                        <i class="bx bx-folder-minus"></i> Remover
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot class="bg-mob-50/50">
                <tr>
                  <td colspan="4" class="px-6 py-3.5 text-sm uppercase font-semibold">
                    <div class="float-right flex items-center">
                      <span class="display-inline">Valor Total de Setup</span>
                      <div class="text-gray-900 sm:text-sm sm:leading-6 text-right w-32">
                        {{ format(totalSetups / 100, moneyString) }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5 pb-5 border-b-2 border-gray-200">
    <h3 class="font-semibold text-lg text-gray-700 mb-4">Condições de Pagamento</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
      <div>
        <label for="contractTime" class="font-semibold mb-1">Vigência do Contrato</label>
        <select
          id="contractTime"
          name="contractTime"
          v-model="formData.paymentCoditions.contractTime"
          @change="calcPriceContract()"
          class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-mob-600 sm:text-sm sm:leading-6"
        >
          <option selected="true" disabled="disabled">Escolha um período</option>
          <option v-for="range in rangeContract" :key="range" :value="range">
            {{ range }}
          </option>
        </select>
      </div>

      <div>
        <p class="font-semibold mb-1">Valor Mensal</p>
        <p
          class="font-semibold bg-gray-200/50 rounded ring-1 ring-black ring-opacity-5 px-4 py-1.5 w-full"
        >
          {{ format(formData.paymentCoditions.monthValue / 100, moneyString) }}
        </p>
      </div>

      <div>
        <p class="font-semibold mb-1">Valor Setup</p>
        <p
          class="font-semibold bg-gray-200/50 rounded ring-1 ring-black ring-opacity-5 px-4 py-1.5 w-full"
        >
          {{ format(formData.paymentCoditions.setupValue / 100, moneyString) }}
        </p>
      </div>

      <div>
        <p class="font-semibold mb-1">Valor Total Contrato</p>
        <p
          class="font-semibold bg-gray-200/50 rounded ring-1 ring-black ring-opacity-5 px-4 py-1.5 w-full"
        >
          {{ format(formData.paymentCoditions.totalValue / 100, moneyString) }}
        </p>
      </div>

      <div>
        <label for="paymentStartDay" class="font-semibold mb-1">Dia início de Pagamento</label>
        <select
          id="paymentStartDay"
          name="paymentStartDay"
          v-model="formData.paymentCoditions.paymentStartDay"
          class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-mob-600 sm:text-sm sm:leading-6"
        >
          <option selected="true" disabled="disabled">Escolha uma data</option>
          <option v-for="date in paymentDays" :key="date" :value="date">
            {{ date }}
          </option>
        </select>
      </div>

      <div>
        <label for="paymentMethod" class="font-semibold mb-1">Forma de Pagamento</label>
        <select
          id="paymentMethod"
          name="paymentMethod"
          v-model="formData.paymentCoditions.paymentMethod"
          class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-mob-600 sm:text-sm sm:leading-6"
        >
          <option selected="true" disabled="disabled">Escolha um período</option>
          <option v-for="method in paymentMethod" :key="method" :value="method">
            {{ method }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="mt-5 pb-5 border-b-2 border-gray-200">
    <h3 class="font-semibold text-lg text-gray-700 mb-4">Observações</h3>
    <textarea
      rows="4"
      name="comment"
      v-model="formData.comment"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600"
    />
  </div>

  <div class="mt-5 pb-5 border-b-2 border-gray-200">
    <h3 class="font-semibold text-lg text-gray-700 mb-4">Condições Especiais</h3>
    <textarea
      rows="4"
      name="specialConditions"
      v-model="formData.specialConditions"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600"
    />
  </div>

  <div class="mt-5 pb-5">
    <div class="flex flex-col space-y-6">
      <div class="w-60">
        <label for="accountable" class="font-semibold mb-1">Responsável pelo Contrato</label>
        <select
          id="accountable"
          name="accountable"
          v-model="formData.accountable"
          class="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-mob-600 sm:text-sm sm:leading-6"
        >
          <option selected="true" disabled="disabled">Escolha seu nome</option>
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }}
          </option>
        </select>
      </div>

      <div>
        <button
          type="button"
          @click="createOS"
          class="rounded-md bg-mob-600 px-8 py-2 font-semibold text-white shadow-md hover:bg-mob-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mob-600 uppercase"
        >
          <span v-if="route.params.id">Duplicar OS</span>
          <span v-else>Gerar OS</span>
        </button>
      </div>
    </div>
  </div>

  <UiLoading :show="loading" />
</template>
