<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute, RouterLink } from "vue-router";
import axios from "@/axios";
import defaultAxios from "axios";
import html2pdf from "html2pdf.js";
import { format } from "v-money3";
import "moment/dist/locale/pt-br";
import moment from "moment";
import { UiLoading } from "@/components";
import { useToast } from "vue-toastification";

const toast = useToast();

const route = useRoute();
const proposta_id = computed(() => route.params.id);
const proposta = ref();
const address = ref();
const rules = ref([]);
const solutions = ref([]);

const loading = ref(false);

const money = {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  suffix: "",
  precision: 2,
  masked: false,
};

const viewOS = async () => {
  try {
    loading.value = !loading.value;

    const response = await axios({
      method: "GET",
      url: "/proposta?proposta_id=" + proposta_id.value,
    });

    proposta.value = response.data;

    const rua = proposta.value.address;
    const number = proposta.value.number;
    const complemento = proposta.value.complemento;
    const neighborhood = proposta.value.neighborhood;
    address.value = `${rua}${number ? ", " + number : ""}${complemento ? ", " + complemento : ""}${
      neighborhood ? ", " + neighborhood : ""
    }`;

    rules.value = proposta.value.services.filter((s) => s.rule !== "" && s.rule !== null);

    solutions.value = proposta.value.services.filter((s) => s.solution_details);

    loading.value = !loading.value;
  } catch (error) {
    loading.value = !loading.value;

    console.error(error);
  }
};

const exportToPDF = async () => {
  const element = document.getElementById("proposta");
  // Adicionar a classe 'print-hide' aos elementos que você deseja esconder
  document.querySelectorAll(".spacer").forEach((el) => el.classList.add("print-hide"));

  const opt = {
    margin: [10, 10],
    filename: `OS 00${proposta.value.id} - ${proposta.value.company_name}.pdf`,
    image: { type: "jpeg", quality: 0.9 },
    html2canvas: { scale: 2 },
    jsPDF: {
      format: "a4",
      orientation: "portrait",
      unit: "px",
      hotfixes: ["px_scaling"],
      precision: 0,
    },
    pagebreak: {
      after: ["#capa"],
      before: ["page1", "#termos", "#capa-contrato", "#contrato", ".breakPage"],
      mode: "avoid-all",
    },
  };

  await html2pdf().set(opt).from(element).save();

  // Remover a classe 'print-hide' após a criação do PDF
  document.querySelectorAll(".spacer").forEach((el) => el.classList.remove("print-hide"));
};

const uploadPDF = async () => {
  loading.value = !loading.value;
  const content = document.getElementById("proposta");

  // Adicionar a classe 'print-hide' aos elementos que você deseja esconder
  document.querySelectorAll(".spacer").forEach((el) => el.classList.add("print-hide"));

  if (content) {
    const pdfOptions = {
      margin: [10, 10],
      filename: `OS 00${proposta.value.id} - ${proposta.value.company_name}.pdf`,
      image: { type: "jpeg", quality: 0.9 },
      html2canvas: { scale: 2 },
      jsPDF: {
        format: "a4",
        orientation: "portrait",
        unit: "px",
        hotfixes: ["px_scaling"],
        precision: 0,
      },
      pagebreak: {
        after: ["#capa"],
        before: ["page1", "#termos", "#capa-contrato", "#contrato", ".breakPage"],
        mode: "avoid-all",
      },
    };

    const pdf = await html2pdf().from(content).set(pdfOptions).output("blob");

    // Remover a classe 'print-hide' após a criação do PDF
    document.querySelectorAll(".spacer").forEach((el) => el.classList.remove("print-hide"));

    const formData = new FormData();
    formData.append("file", pdf, pdfOptions.filename);
    formData.append("proposta_id", proposta.value.id);
    formData.append("mobcall_signer", proposta.value.mobcall_signer);
    formData.append("company_signer", proposta.value.signing_email);
    formData.append("sendmail", false);

    const config = {
      method: "POST",
      url: "/uploadOs",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      await axios(config);
      // await defaultAxios(config);

      loading.value = !loading.value;
      toast.success("Ordem de serviço enviada com sucesso!", {
        timeout: 3000,
      });
    } catch (error) {
      loading.value = !loading.value;
      toast.error("Houve um problema com o envio, tente novamente mais tarde.", {
        timeout: 3000,
      });
      console.error("Erro ao enviar o PDF", error);
    }
  } else {
    console.error('Elemento com ID "content-to-pdf" não encontrado ou vazio.');
  }
};

onMounted(() => {
  viewOS();
});
</script>

<template>
  <div class="lg:fixed lg:top-0 lg:w-[calc(100%-22rem)] bg-mob-600 p-2 shadow-md z-50">
    <div class="flex items-center justify-between space-x-2">
      <div class="flex flex-col lg:flex-row lg:items-center space-y-2 lg:space-y-0 lg:space-x-5">
        <button
          @click="exportToPDF"
          class="border border-gray-400 bg-white hover:bg-red-600 hover:border-red-400 text-gray-800 hover:text-white shadow px-2 py-1 font-semibold rounded flex items-center space-x-1"
        >
          <i class="bx bx-sm bxs-file-pdf"></i>
          <span>Baixar PDF</span>
        </button>

        <button
          @click="uploadPDF"
          class="border border-gray-400 bg-white hover:bg-blue-600 hover:border-blue-400 text-gray-800 hover:text-white shadow px-2 py-1 font-semibold rounded flex items-center space-x-1"
        >
          <i class="bx bx-sm bx-mail-send"></i>
          <span>Enviar para D4Sign</span>
        </button>

        <router-link
          :to="{ name: 'PageListOs' }"
          class="border border-gray-400 bg-white hover:bg-gray-800 hover:border-gray-600 text-gray-800 hover:text-white shadow px-2 py-1 font-semibold rounded flex items-center space-x-1"
        >
          <i class="bx bx-sm bx-arrow-back"></i>
          <span>Voltar para Ordens de Serviço</span>
        </router-link>
      </div>
    </div>
  </div>

  <div class="shadow-md ring-1 ring-black ring-opacity-5 rounded">
    <div id="proposta" class="h-full bg-white">
      <div id="capa" class="flex flex-col items-center mx-auto h-screen">
        <img class="w-64 mt-80" src="/logomob.png" alt="Logo Mobcall" />
        <h1 class="mt-6 font-semibold text-xl text-gray-700 text-center">
          OS 00{{ proposta?.id }} - {{ proposta?.company_name }}
        </h1>
      </div>

      <div class="spacer pt-0.5 w-full bg-mob-500 my-16"></div>

      <div id="page1" class="bg-white px-5">
        <img class="w-28" src="/logomob.png" alt="Logo Mobcall" />

        <h2 class="text-mob-800 text-xl font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
          Contratante
        </h2>
        <div class="p-1 grid grid-cols-2 gap-10 text-sm">
          <div>
            <p class="font-semibold">{{ proposta?.company_name }}</p>
            <p>{{ proposta?.company_document }}</p>
            <p>{{ proposta ? address : "" }}</p>
            <p>{{ proposta?.city }}, {{ proposta?.uf }}, {{ proposta?.zipcode }}</p>
            <p>{{ proposta?.company_phone }} - {{ proposta?.company_email }}</p>
          </div>

          <div>
            <h3 class="font-semibold text-gray-500 mt-2">Representante Autorizado</h3>
            <p class="font-semibold">
              {{ proposta?.signing_name }} - {{ proposta?.signing_ocupation }}
            </p>
            <p>{{ proposta?.signing_phone }}</p>
            <p>{{ proposta?.signing_email }}</p>
          </div>
        </div>

        <div v-if="proposta?.services">
          <h2 class="text-mob-800 text-lg font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
            Descrição dos Serviços
          </h2>
          <div class="p-1 inline-block min-w-full">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <td class="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Serviço
                  </td>
                  <td class="px-3 py-2 text-sm font-semibold text-gray-900 text-right">
                    Quantidade
                  </td>
                  <td class="px-3 py-2 text-sm font-semibold text-gray-900 text-right">
                    Valor unitário
                  </td>
                  <td class="px-3 py-2 text-sm font-semibold text-gray-900 text-right">
                    Valor total
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item of proposta?.services" :key="item.id" class="even:bg-gray-50">
                  <td
                    class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
                  >
                    {{ item.name }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500 text-right">
                    {{ item.qtd }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500 text-right">
                    {{ item.vUnit }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-2 text-sm font-semibold text-gray-900 text-right"
                  >
                    {{ format(item.vTotal / 100, money) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h2 class="text-mob-800 text-lg font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
            Minutagem
          </h2>
          <div class="grid grid-cols-4 gap-4 p-1 text-sm">
            <div class="font-semibold">
              <p class="text-gray-500">Fixo</p>
              <p v-if="proposta?.min_fixo">{{ format(proposta?.min_fixo / 100, money) }}</p>
              <p v-else>---</p>
            </div>

            <div class="font-semibold">
              <p class="text-gray-500">Móvel</p>
              <p v-if="proposta?.min_movel">{{ format(proposta?.min_movel / 100, money) }}</p>
              <p v-else>---</p>
            </div>

            <div class="font-semibold">
              <p class="text-gray-500">Fixo Internacional</p>
              <p v-if="proposta?.min_fixo_iternacional">
                {{ format(proposta?.min_fixo_iternacional / 100, money) }}
              </p>
              <p v-else>---</p>
            </div>

            <div class="font-semibold">
              <p class="text-gray-500">Móvel Internacional</p>
              <p v-if="proposta?.min_movel_iternacional">
                {{ format(proposta?.min_movel_iternacional / 100, money) }}
              </p>
              <p v-else>---</p>
            </div>

            <div class="font-semibold">
              <p class="text-gray-500">0800/400X Fixo</p>
              <p v-if="proposta?.min_gratuito_fixo">
                {{ format(proposta?.min_gratuito_fixo / 100, money) }}
              </p>
              <p v-else>---</p>
            </div>

            <div class="font-semibold">
              <p class="text-gray-500">0800/400X Móvel</p>
              <p v-if="proposta?.min_gratuito_movel">
                {{ format(proposta?.min_gratuito_movel / 100, money) }}
              </p>
              <p v-else>---</p>
            </div>

            <div class="font-semibold">
              <p class="text-gray-500">Discador Fixo</p>
              <p v-if="proposta?.min_discador_fixo">
                {{ format(proposta?.min_discador_fixo / 100, money) }}
              </p>
              <p v-else>---</p>
            </div>

            <div class="font-semibold">
              <p class="text-gray-500">Discador Móvel</p>
              <p v-if="proposta?.min_discador_movel">
                {{ format(proposta?.min_discador_movel / 100, money) }}
              </p>
              <p v-else>---</p>
            </div>

            <div class="font-semibold">
              <p class="text-gray-500">Número Móvel</p>
              <p v-if="proposta?.min_numero_movel">
                {{ format(proposta?.min_numero_movel / 100, money) }}
              </p>
              <p v-else>---</p>
            </div>
          </div>
        </div>

        <div v-if="proposta?.setups">
          <h2 class="text-mob-800 text-lg font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
            Descrição dos Setups
          </h2>
          <div class="p-1 inline-block min-w-full">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <td class="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Setup
                  </td>
                  <td class="px-3 py-2 text-sm font-semibold text-gray-900 text-right">
                    Quantidade
                  </td>
                  <td class="px-3 py-2 text-sm font-semibold text-gray-900 text-right">
                    Valor unitário
                  </td>
                  <td class="px-3 py-2 text-sm font-semibold text-gray-900 text-right">
                    Valor total
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item of proposta?.setups" :key="item.id" class="even:bg-gray-50">
                  <td
                    class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
                  >
                    {{ item.setup_name }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500 text-right">
                    {{ item.qtd }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500 text-right">
                    {{ item.vUnit }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-2 text-sm font-semibold text-gray-900 text-right"
                  >
                    {{ format(item.vTotal / 100, money) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="solutions.length > 0">
          <h2 class="text-mob-800 text-lg font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
            Soluções Técnicas
          </h2>
          <div class="p-1 inline-block min-w-full">
            <ul>
              <li v-for="solution of solutions" :key="solution.id" class="px-2 py-1 text-sm">
                <p class="font-semibold">{{ solution.name }}</p>
                <ul
                  v-html="solution.solution_details"
                  class="list-disc list-inside p-1 leading-5 mb-2 text-xs"
                ></ul>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="rules.length > 0">
          <h2 class="text-mob-800 text-lg font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
            Observações importantes
          </h2>
          <div class="p-1 inline-block min-w-full">
            <ul>
              <li v-for="rule of rules" :key="rule.id" class="px-2 py-1 even:bg-gray-50 text-sm">
                <p class="font-semibold">{{ rule.name }}</p>
                <p>{{ rule.rule }}</p>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="proposta?.comment">
          <h2 class="text-mob-800 text-lg font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
            Observações
          </h2>
          <p class="p-2 text-sm">{{ proposta?.comment }}</p>
        </div>
      </div>

      <div class="spacer pt-0.5 w-full bg-mob-500 my-16"></div>

      <div id="termos" class="bg-white px-5">
        <div>
          <h2 class="text-mob-800 text-lg font-semibold mb-1 border-b-2 border-mob-200 pb-3">
            Condições de Pagamento
          </h2>
          <div class="grid grid-cols-3 gap-4 p-1 text-sm">
            <div class="font-semibold">
              <p class="text-gray-500">Vigência do Contrato</p>
              <p>{{ proposta?.contractTime }}</p>
            </div>
            <div class="font-semibold">
              <p class="text-gray-500">Valor Mensal</p>
              <p>{{ format(proposta?.monthValue / 100, money) }}</p>
            </div>
            <div class="font-semibold">
              <p class="text-gray-500">Valor Setup</p>
              <p>{{ format(proposta?.setupValue / 100, money) }}</p>
            </div>
            <div class="font-semibold">
              <p class="text-gray-500">Valor Total do Contrato</p>
              <p>{{ format(proposta?.totalValue / 100, money) }}</p>
            </div>
            <div class="font-semibold">
              <p class="text-gray-500">Dia início de Pagamento</p>
              <p>dia {{ proposta?.paymentStartDay }}</p>
            </div>
            <div class="font-semibold">
              <p class="text-gray-500">Forma de Pagamento</p>
              <p>{{ proposta?.paymentMethod }}</p>
            </div>
          </div>
        </div>

        <h2 class="text-mob-800 text-lg font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
          Termos e Condições
        </h2>
        <div class="px-3 py-2 mb-2 space-y-3 text-sm">
          <p>
            1. Assinando embaixo, o cliente reconhece e concorda que: O cliente leu e está de acordo
            com a MobCall Tecnologia e Serviços de Marketing Limitada (Termos do Cliente), que pode
            ser acessado ser acessado
            <a
              class="text-mob-600"
              target="_blank"
              href="https://irp.cdn-website.com/edb67703/files/uploaded/Mobcall_ToS_210726.pdf"
              >Link para Termos e Condições</a
            >, e com todos os Termos de Produto aplicáveis (como definido em Termos do Cliente), e
            em conjunto com esta ordem de inserção coletivamente denominado ""Contrato"".
          </p>
          <p>
            2. O cliente não tem permissão para cancelar ou rescindir o Contrato, exceto se
            expressamente permitido conforme descrito no Contrato.
          </p>
          <p>
            3. O Contrato autoriza a Mobcall Brasil a receber os devidos montantes descritos nos
            Termos do Contrato.
          </p>
          <p>4. Em caso de cancelamento, avisar com 30 dias de antecedência.</p>
        </div>

        <div class="mt-20 text-sm">
          <p class="font-semibold px-3 py-2 my-20">
            Barueri, {{ moment(proposta?.created_at).format("DD MMMM YYYY") }}
          </p>

          <div class="grid grid-cols-2 px-6 pt-32 gap-x-20">
            <p class="font-bold py-1 mb-2 uppercase border-t border-black w-full">
              {{ proposta?.signing_name }}
            </p>
            <p class="font-bold py-1 mb-2 uppercase border-t border-black w-full">
              {{ proposta?.created_by }}
            </p>
          </div>
        </div>
      </div>

      <div class="spacer pt-0.5 w-full bg-mob-500 my-16"></div>

      <div id="capa-contrato" class="bg-white px-5">
        <h1 class="font-semibold text-lg text-gray-700 text-center px-24">
          CONTRATO DE LICENÇA DE USO DE SOFTWARE COMO SERVIÇO E PRESTAÇÃO DE SERVIÇOS
        </h1>
        <h2 class="font-semibold text-xl text-gray-700 text-center">(“CONTRATO”)</h2>

        <h2 class="text-mob-800 text-xl font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
          Contratante
        </h2>
        <div class="p-1 grid grid-cols-2 gap-10 text-sm">
          <div>
            <p class="font-semibold">{{ proposta?.company_name }}</p>
            <p>{{ proposta?.company_document }}</p>
            <p>{{ proposta ? address : "" }}</p>
            <p>{{ proposta?.city }}, {{ proposta?.uf }}, {{ proposta?.zipcode }}</p>
            <p>{{ proposta?.company_phone }} - {{ proposta?.company_email }}</p>
          </div>

          <div>
            <h3 class="font-semibold text-gray-500 mt-2">Contato / Responsável</h3>
            <p class="font-semibold">
              {{ proposta?.signing_name }} - {{ proposta?.signing_ocupation }}
            </p>
            <p>{{ proposta?.signing_phone }}</p>
            <p>{{ proposta?.signing_email }}</p>
          </div>
        </div>

        <h2 class="text-mob-800 text-xl font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
          Contratada
        </h2>
        <div class="p-1 grid grid-cols-2 gap-10 text-sm">
          <div>
            <p class="font-semibold">MOBCALL TECNOLOGIA E INTELIGÊNCIA DE DADOS LTDA.</p>
            <p>29.183.747/0001-04</p>
            <p>Alameda Rio Negro, nº 503, sala 2020, Alphaville, Barueri/SP, CEP 06.454-000</p>
          </div>

          <div>
            <h3 class="font-semibold text-gray-500">Contato / Responsável</h3>
            <p class="font-semibold">
              {{ proposta?.created_by }}
            </p>
          </div>
        </div>

        <h2 class="text-mob-800 text-xl font-semibold mt-8 border-b-2 border-mob-200 pb-3">
          Quadro Resumo do Contrato
        </h2>

        <div class="text-sm">
          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Objeto</p>
            <p class="border w-full p-2">
              Prestação de Serviços Mobcall vide Anexo I – Ordem de Serviço
            </p>
          </div>

          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Remuneração</p>
            <p class="border w-full p-2">Vide Anexo I – Ordem de Serviço</p>
          </div>

          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Prazo e forma de pagamento</p>
            <p class="border w-full p-2">
              Data de vencimento vide Anexo I – Ordem de Serviço, e pagamento mediante envio à
              CONTRATANTE de Nota Fiscal Eletrônica e boleto eletrônico, com antecedência mínima de
              2 (dois) dias da data de vencimento.
            </p>
          </div>

          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Atraso no pagamento da Remuneração</p>
            <p class="border w-full p-2">
              Multa de 2% (dois por cento) e juros de 1% (um por cento) ao mês, pro rata die, sobre
              o valor em atraso, devidos até a data do efetivo pagamento.
            </p>
          </div>

          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Reajuste</p>
            <p class="border w-full p-2">
              A cada 12 (doze) meses contados a partir do início da vigência do Contrato, de acordo
              com a variação positiva do IGP-M.
            </p>
          </div>

          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Vigência</p>
            <p class="border w-full p-2">
              Data de início da vigência:<br />Prazo inicial do Contrato:
            </p>
          </div>

          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Renovação</p>
            <p class="border w-full p-2">Automática, por períodos iguais e sucessivos.</p>
          </div>

          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Rescisão imotivada pela Contratante</p>
            <p class="border w-full p-2">
              Mediante envio de comunicação por escrito à MOBCALL, com antecedência de 30 (trinta)
              dias, e aplicação de penalidade da Cláusula 4.3.
            </p>
          </div>

          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Anexos</p>
            <p class="border w-full p-2">
              Anexo I – Ordem de Serviço<br />Anexo II – SLA<br />Anexo III – Política de
              Privacidade e Tratamento de Dados Pessoais
            </p>
          </div>

          <div class="flex">
            <p class="w-[200px] font-semibold border p-2">Foro</p>
            <p class="border w-full p-2">Barueri/SP, Brasil</p>
          </div>
        </div>

        <div v-if="proposta?.specialConditions">
          <h2 class="text-mob-800 text-lg font-semibold mt-8 mb-1 border-b-2 border-mob-200 pb-3">
            Condições Especiais
          </h2>
          <p class="p-2 text-sm">{{ proposta?.specialConditions }}</p>
        </div>

        <div class="mt-10 font-semibold text-sm flex flex-col items-center">
          <p class="text-center w-[600px]">
            Ao assinar abaixo, a CONTRATANTE declara que leu e concordou com a integralidade dos
            termos, condições, direitos e obrigações dispostos neste Contrato e em seus Anexos.
          </p>

          <div class="border-b-2 border-gray-400 mt-20 mb-5 w-[600px]"></div>

          <div class="space-y-2 w-[600px]">
            <p>Contratante: {{ proposta?.company_name }}</p>
            <p>Representada por: {{ proposta?.signing_name }}</p>
            <p>Data: {{ moment(proposta?.created_at).format("DD MMMM YYYY") }}</p>
          </div>
        </div>
      </div>

      <div class="spacer pt-0.5 w-full bg-mob-500 my-16"></div>

      <div id="contrato" class="bg-white px-5">
        <div class="space-y-4 text-sm pb-10">
          <p class="font-semibold text-lg text-gray-700 text-center px-24">
            CONTRATO DE LICENÇA DE USO DE SOFTWARE COMO SERVIÇO E PRESTAÇÃO DE SERVIÇOS
          </p>
          <p class="font-bold pt-3">1. Objeto</p>
          <p>
            <b>1.1</b> O objeto do presente Contrato consiste na (i) concessão, pela MOBCALL à
            CONTRATANTE, de licença de uso e acesso à plataforma de softwares de serviços MOBCALL
            (“Serviços Mobcall” e “Plataforma”, respectivamente) descritos na Ordem de Serviço, que
            integra o presente Contrato como “Anexo I – Ordem de Serviço”, por meio do ambiente da
            internet, em caráter pessoal, limitado, oneroso, intransferível, temporário, revogável e
            não exclusivo (“Licença”); bem como (ii) a prestação de serviços de suporte e manutenção
            relacionados ao uso da tecnologia dos Serviços Mobcall (“Serviços de Assistência”, e,
            quando em conjunto com Serviços Mobcall, os “Serviços”).
          </p>
          <p>
            <b>1.2</b> A presente Licença autoriza o uso dos Serviços aos empregados da CONTRATANTE
            devidamente autorizados, identificados e dentro do limite de usuários descritos no Anexo
            I – Ordem de Serviço (“Usuários”), sendo vedado aos Usuários a divulgação de suas
            credenciais de acesso para terceiros, bem como a utilização dos Serviços Mobcall em
            desacordo com o previsto no presente Contrato.
          </p>
          <p class="font-bold pt-5">2. Nível de Serviço</p>
          <p>
            <b>2.1</b> A MOBCALL prestará os Serviços de acordo com as condições e níveis de serviço
            estabelecidos no Acordo de Nível de Serviço – Service Level Agreement, que integra o
            presente Contrato como “Anexo II - SLA”.
          </p>
          <p class="font-bold pt-5">3. Remuneração e Forma de Pagamento</p>
          <p>
            <b>3.1</b> Em contrapartida aos Serviços, a MOBCALL fará jus a remuneração de acordo com
            as regras definidas no Anexo I – Ordem de Serviço (“Remuneração”), levando em
            consideração os pacotes de serviços contratados e suas quantidades, o volume de minutos
            e o volume de minutos de processamento de ligações ou conversas, bem como o valor
            referente à configuração inicial dos serviços.
          </p>
          <p>
            <b>3.2</b> Nos casos de contratação de Serviços que prevejam quantidade de minutos e
            volume de minutos/conversas processadas, o consumo de minutagem/quantidade superior ao
            limite de minutos previsto no Anexo I – Ordem de Serviço acarretará a cobrança de um
            valor adicional por minuto/conversa, calculado conforme disciplinado no Anexo I – Ordem
            de Serviço, o qual será devido pela CONTRATANTE no mês imediatamente subsequente ao
            consumo de minutagem excedente, na data de vencimento e demais condições descritas na
            Cláusula 3.3 abaixo.
          </p>
          <p>
            <b>3.3</b> A Remuneração será paga mensalmente pela CONTRATANTE na data de vencimento
            fixada no Anexo I – Ordem de Serviço, mediante a apresentação pela MOBCALL na Ordem de
            Serviço incluindo descrição das cobranças totais dos Serviços referente ao mês
            imediatamente posterior ao pagamento, bem como a respectiva Nota Fiscal Eletrônica e
            boleto eletrônico, com antecedência mínima de 2 (dois) dias da data de vencimento. Caso
            a data de pagamento recaia em dia não útil, o pagamento será prorrogado para o primeiro
            dia útil subsequente.
          </p>
          <p>
            <b>3.3.1</b> Em caso de utilização, pela CONTRATANTE, de minutagem adicional à
            contratada, esta será calculada conforme disposto na Cláusula 3.2 acima e será faturada
            mensalmente no primeiro dia do mês imediatamente subsequente à utilização da referida
            minutagem, mediante apresentação, pela MOBCALL, de Fatura de Serviços Complementar com a
            relação da minutagem adicional efetivamente utilizada, bem como a respectiva Nota Fiscal
            Eletrônica e boleto eletrônico, com antecedência mínima de 2 (dois) dias da data de
            vencimento. Caso a data de pagamento recaia em dia não útil, o pagamento será prorrogado
            para o primeiro dia útil subsequente.
          </p>
          <p>
            <b>3.4</b> Todo e qualquer atraso da CONTRATANTE no pagamento de quaisquer valores
            oriundos do presente Contrato e seus Anexos sujeitará a CONTRATANTE ao adimplemento do
            valor não pago corrigido monetariamente com base na variação positiva do IGP-M, da data
            do vencimento até a efetiva quitação, acrescido de juros moratórios de 1% (um por cento)
            ao mês, pro rata die, bem como de multa de 2% (dois por cento) sobre o valor total
            devido.
          </p>
          <p>
            <b>3.5</b> O atraso pela CONTRATANTE no pagamento da Remuneração devida à MOBCALL por
            período igual ou superior a 15 (quinze) dias corridos contados da data do vencimento
            poderá acarretar na suspensão dos Serviços, independentemente de aviso prévio, até que
            as pendências financeiras tenham sido regularizadas pela CONTRATANTE. O acesso aos
            Serviços Mobcall e a prestação dos Serviços de Assistência somente serão reestabelecidos
            após a identificação, pela MOBCALL, do pagamento integral de todos os valores devidos
            pela CONTRATANTE, o que poderá ocorrer em até 2 (dois) dias úteis após a data de
            pagamento pela CONTRATANTE.
          </p>
          <p>
            <b>3.6</b> Caso a CONTRATANTE não quite suas obrigações financeiras no prazo de 30
            (trinta) dias contados da data de vencimento do valor da Remuneração em aberto, a
            MOBCALL se reserva o direito de rescindir o presente Contrato e cancelar definitivamente
            o acesso da CONTRATANTE aos Serviços Mobcall, aplicando-se o disposto na Cláusula
            4.2(vii).
          </p>
          <p>
            <b>3.7</b> Os valores da Remuneração serão reajustados automaticamente a cada 12 (doze)
            meses de relação contratual, contados a partir do início da vigência do presente
            Contrato, conforme disposto na Cláusula 4.1 abaixo, de acordo com a variação positiva do
            IGP- M/FGV ou outro índice que vier a substituí-lo, independente aviso prévio pela
            MOBCALL à CONTRATANTE. Em caso de deflação no período, os valores da Remuneração não
            sofrerão alteração.
          </p>
          <p class="font-bold pt-5">4. Vigência e Rescisão</p>
          <p>
            <b>4.1</b> O presente Contrato permanecerá em vigor pelo período de contrato
            inicialmente fixado no Anexo I – Ordem de Serviço, contado a partir da data de
            vencimento do primeiro pagamento nos exatos termos fixados no Anexo I – Ordem de
            Serviço. Caso o CONTRATANTE, por sua mera liberalidade, realize o pagamento em data
            anterior à data de vencimento do primeiro pagamento conforme descrito no Anexo I – Ordem
            de Serviço, fica certo e ajustado que o Contrato iniciará sua vigência na data do
            efetivo pagamento.
          </p>
          <p>
            <b>4.1.1</b> O Contrato será renovado automaticamente, por períodos iguais e sucessivos,
            salvo em caso de notificação escrita encaminhada por uma Parte à outra informando a
            intenção expressa de não renovar o presente Contrato, com antecedência de 30 (trinta)
            dias da data de término do período contratual em curso.
          </p>
          <p><b>4.2</b> O presente Contrato poderá ser rescindido nas seguintes hipóteses:</p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(i)</span> por iniciativa da MOBCALL, imotivadamente,
            mediante envio de comunicação por escrito à CONTRATANTE, com antecedência de 90
            (noventa) dias da data de término do período contratual em curso;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(ii)</span> por iniciativa da CONTRATANTE, imotivadamente
            e a qualquer momento, mediante envio de comunicação por escrito à MOBCALL, com
            antecedência de 30 (trinta) dias, sendo aplicável a penalidade prevista na Cláusula 4.3
            abaixo.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(iii)</span> na hipótese de inadimplemento e/ou
            descumprimento por uma das Partes de qualquer declaração e/ou obrigação assumida neste
            Contrato, desde que a Parte inocente envie notificação por escrito à Parte infratora,
            estabelecendo prazo de 15 (quinze) dias para regularização e esta não corrija a
            irregularidade em aludido prazo, respondendo, neste caso, a Parte infratora pelas perdas
            e danos a que der causa, não aplicando-se esta disposição em caso de rescisão motivada
            por atraso no pagamento da Remuneração pela CONTRATANTE, para a qual há regramento
            específico;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(iv)</span> falência, liquidação judicial ou
            extrajudicial, recuperação judicial ou extrajudicial de uma das Partes;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(v)</span> ocorrência de caso fortuito ou de força maior
            que torne a execução do presente Contrato impossível ou impraticável por prazo superior
            a 30 (trinta) dias, contados da data de recebimento da notificação sobre evento de força
            maior ou caso fortuito enviada pela Parte prejudicada;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(vi)</span> transferência ou cessão, parcial ou total,
            pela CONTRATANTE, das obrigações previstas neste Contrato, sem a prévia e expressa
            autorização da MOBCALL;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(vii)</span> atraso da CONTRATANTE no pagamento da
            Remuneração devida à MOBCALL por período superior a 30 (trinta) dias, nos termos da
            Cláusula 3.6 acima.
          </p>
          <p>
            <b>4.3</b> Caso a rescisão imotivada se dê por iniciativa da CONTRATANTE ou caso a
            rescisão motivada se dê por culpa da CONTRATANTE, esta ficará (i) obrigada ao pagamento
            de multa rescisória correspondente a 20% (vinte por cento) do valor da Remuneração
            devida à MOBCALL até o final da vigência do período do Contrato em curso OU (ii) a
            integralidade do valor equivalente à Remuneração correspondente a 1 (um) mês de vigência
            do Contrato, o que for maior. A multa rescisória prevista nesta Cláusula não isenta a
            CONTRATANTE de sua obrigação de quitar os valores devidos à MOBCALL até a data de
            rescisão do Contrato.
          </p>
          <p>
            <b>4.4</b> Após encerrado o prazo de vigência deste Contrato ou após sua rescisão, por
            qualquer motivo, os acessos aos Serviços Mobcall serão imediatamente encerrados pela
            MOBCALL, sem necessidade de qualquer aviso ou notificação. A continuidade do uso dos
            Serviços pela CONTRATANTE após o prazo estipulado nesta cláusula sujeitará a CONTRATANTE
            ao pagamento dos valores correspondentes à utilização indevida.
          </p>
          <p class="font-bold pt-5">5. Acesso a Informações</p>
          <p>
            <b>5.1</b> A CONTRATANTE está ciente de que a MOBCALL não oferece serviços de hospedagem
            e, portanto, os dados, informações e resultados armazenados na conta da CONTRATANTE
            permanecerão disponíveis na Plataforma para acesso da CONTRATANTE por até 90 (noventa)
            dias contados da data de encerramento deste Contrato, por qualquer motivo (“Prazo de
            Backup”).
          </p>
          <p>
            <b>5.2</b> A MOBCALL não se responsabiliza pela perda dos dados, informações e
            resultados da conta da CONTRATANTE, sendo de sua responsabilidade exclusiva realizar o
            backup, em seu servidor e/ou dispositivos, de todos dados, informações e resultados que
            estejam armazenados na Plataforma durante a vigência deste Contrato e pelo Prazo de
            Backup. Após o Prazo de Backup, todos os dados, informações e resultados da CONTRATANTE
            poderão, a critério exclusivo da MOBCALL, ser apagados da Plataforma, de forma
            definitiva e irrecuperável, e, em hipótese alguma, a MOBCALL será responsabilizada por
            qualquer dano ou prejuízo causado à CONTRATANTE, caso esta não realize as cópias de
            segurança aqui mencionadas.
          </p>
          <p class="font-bold pt-5">6. Declarações e Obrigações Gerais da MOBCALL</p>
          <p>
            <b>6.1</b> Sem prejuízo das demais disposições do presente Contrato, a MOBCALL declara e
            se obriga, durante o período de vigência do Contrato, a:
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">a)</span> prestar os Serviços de acordo com os níveis de
            serviço estabelecidos no Anexo II – SLA.
          </p>
          <p class="font-bold pt-5">7. Declarações e Obrigações Gerais Da CONTRATANTE</p>
          <p>
            <b>7.1</b> Sem prejuízo das demais disposições do presente Contrato, a CONTRATANTE
            declara e se obriga, durante todo o período de vigência do Contrato, a:
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">a)</span> não realizar engenharia reversa ou qualquer
            derivação dos Serviços Mobcall;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">b)</span> não autorizar que terceiros, que não sejam os
            Usuários, acessem os Serviços Mobcall, ou de qualquer maneira se beneficiem dos
            Serviços.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">c)</span> utilizar os Serviços Mobcall exclusivamente para
            as finalidades de otimizar o atendimento realizado aos seus clientes, facilitar a
            integração de sua área de atendimento com outras áreas de negócios e aprimorar as suas
            estratégias de marketing e de vendas, dentro dos limites estabelecidos por este
            Contrato.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">d)</span> equipar-se e responsabilizar-se pelos
            computadores e dispositivos de hardware necessários para o acesso aos Serviços, bem como
            pelo acesso desses computadores e dispositivos à internet;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">e)</span> manter seguro o ambiente de seus computadores e
            demais dispositivos de acesso aos Serviços, com o uso de ferramentas disponíveis, como
            antivírus e firewall, entre outras, de modo a contribuir na prevenção de riscos
            eletrônicos;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">f)</span> utilizar sistemas operacionais e navegadores
            atualizados e eficientes para a plena utilização dos Serviços, de acordo com as
            especificações fornecidas pela MOBCALL;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">g)</span> usar os Serviços Mobcall, se aplicável, nas
            versões mais recentes disponíveis, sabendo e concordando que a MOBCALL poderá, a
            qualquer momento e a seu exclusivo critério, definir a data final para a descontinuidade
            dos serviços de suporte para as versões anteriores; e
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">h)</span> utilizar os Serviços para finalidades lícitas e
            não permitir o seu acesso e uso por terceiros não autorizados.
          </p>
          <p class="font-bold pt-5">8. Responsabilidade</p>
          <p>
            <b>8.1</b> A MOBCALL, em hipótese nenhuma, será responsabilizada por qualquer
            consequência ou dano proveniente de decisões que sejam tomadas pela CONTRATANTE com base
            nas análises, diagnósticos, monitoramentos realizados a partir do uso de dados gerados
            ou intermediados pelos Serviços Mobcall, e a CONTRATANTE, desde já, isenta a MOBCALL de
            toda e qualquer responsabilidade pelas perdas e danos, diretos e indiretos, causados à
            própria CONTRATANTE ou a terceiros em razão de tais decisões.
          </p>
          <p>
            <b>8.2</b> A CONTRATANTE declara e concorda que o resultado do uso de Serviços Mobcall é
            típica obrigação de meio, de forma que a MOBCALL não garante qualquer rendimento
            econômico, economia ou resultado comercial em relação a Licença concedida e as
            funcionalidades oferecidas de acordo com os serviços contratados, eximindo-se de toda
            responsabilidade no caso de danos e prejuízos de qualquer natureza causados à
            CONTRATANTE nesse sentido.
          </p>
          <p class="font-bold pt-5">9. Propriedade Intelectual</p>
          <p>
            <b>9.1</b> Propriedade Intelectual da MOBCALL. As Partes aceitam e concordam que os
            Serviços Mobcall, bem como todo o know-how, base de dados, técnicas, metodologias,
            patentes, marcas registradas, segredos de negócio, planos de negócio, documentos
            técnicos, invenções, informações, códigos de programação, programas de computador,
            linguagens informáticas, avançadas ou não, e métodos de captura, tratamento e
            processamento das bases de dados utilizados pela MOBCALL para a execução do objeto deste
            Contrato e/ou desenvolvidas pela MOBCALL para a consecução do seu objeto (“Propriedade
            Intelectual”) são e continuarão sendo de titularidade exclusiva da MOBCALL. Nenhuma
            disposição deste Contrato deve ser entendida como restrição ou renúncia da MOBCALL de
            quaisquer direitos sobre sua Propriedade Intelectual, tampouco cessão à CONTRATANTE de
            tais direitos.
          </p>
          <p>
            <b>9.1.1</b> Os códigos derivados, direta ou indiretamente, de qualquer desenvolvimento,
            arranjo, melhoria ou alteração de Serviços Mobcall, das suas funcionalidades e das suas
            bases de dados relacionadas, executados ou entregues durante ou em razão do cumprimento
            deste Contrato, ainda que por solicitação expressa da CONTRATANTE, serão de exclusiva
            titularidade da MOBCALL, podendo a esta empregá-los livremente e, sem limitação,
            adaptá-los, aperfeiçoá-los, transformá-los, distribui-los, comercializá-los, levá-los a
            registro, cedê-los e licenciá-los, a qualquer título e a seu exclusivo critério.
          </p>
          <p>
            <b>9.1.2</b> Cada uma das Partes permanecerá sendo proprietária de seus direitos de
            propriedade intelectual. Nada neste Contrato deve afetar, diminuir ou alterar os
            direitos de propriedade existentes de qualquer uma das Partes.
          </p>
          <p>
            <b>9.2</b> Usos Proibidos. A Licença não confere à CONTRATANTE, tampouco aos Usuário,
            qualquer direito além daqueles expressamente outorgados neste Contrato. A CONTRATANTE
            concorda que a Plataforma é protegida pela legislação de propriedade intelectual, sendo
            vedado à CONTRATANTE e aos Usuário qualquer forma de utilização de Serviços em desacordo
            com as disposições previstas neste Contrato, bem como: (i) ceder, sublicenciar, doar,
            alienar, vender, alugar, arrendar, emprestar, exportar, importar, distribuir, realizar
            ou permitir time sharing, transmitir ou transferir, total ou parcialmente, a terceiros,
            a qualquer título, a Plataforma, quaisquer licenças e/ou direitos de acesso e/ou uso de
            Serviços, exceto se autorizado expressamente neste Contrato; (ii) modificar, ampliar,
            reduzir, adaptar, traduzir, descompilar, emular, desmontar, descriptografar, executar
            engenharia reversa de Serviços; (iii) desenvolver, criar ou patrocinar programas que
            possam alterar ou copiar a Plataforma, ainda que seja para lhe introduzir melhorias de
            qualquer natureza; (iv) explorar, reproduzir e criar cópias, digitais ou físicas, de
            Serviços em qualquer mídia, de forma que viole os direitos da MOBCALL; (v) separar os
            componentes de Serviços para uso em dispositivos diferentes; e (vi) remover, ocultar ou
            alterar, total ou parcialmente, as marcas e sinais distintivos da MOBCALL de Serviços,
            bem como os avisos de reserva de direitos de propriedade intelectual existentes na
            Plataforma e na documentação fornecidas pela Plataforma e/ou pela MOBCALL.
          </p>
          <p>
            <b>9.3</b> Responsabilidade. A MOBCALL declara e garante que toda a Propriedade
            Intelectual relacionada à Plataforma Mobcall e/ou executadas ou entregues pela MOBCALL
            durante ou em razão do cumprimento do Contrato é de sua titularidade, co-titularidade
            e/ou, conforme o caso, que possui todas as licenças, autorizações, cessões ou permissões
            para a sua comercialização, utilização, outorga da Licença ou sublicenciamento.
          </p>
          <p>
            <b>9.3.1</b> Cada Parte deverá responsabilizar-se, perante a Parte contrária e
            terceiros, pela autoria, originalidade e legalidade de qualquer material, dado ou
            conteúdo que disponibilize para o cumprimento das obrigações previstas no Contrato,
            garantindo a não violação de quaisquer leis, decretos, regulamentos, normas de
            autorregulamentação e/ou direitos de terceiros, incluindo-se, mas não se limitando a
            direitos de personalidade, direitos de imagem, direitos de propriedade intelectual,
            direitos autorais, marcas, patentes, segredos de indústria e comércio, direitos de
            imagem e regulamentos de promoções comerciais, exceto na forma autorizada pelo Contrato
            ou pela legislação pertinente.
          </p>
          <p>
            <b>9.4</b> Gravações. Todas as gravações realizadas pela Plataforma e/ou realizadas pela
            CONTRATANTE e armazenadas na Plataforma, bem como os respectivos áudios, vídeos,
            análises, relatórios, entre outros, que forem gerados no âmbito de Serviços a partir das
            chamadas telefônicas ou vídeo chamadas realizadas pela CONTRATANTE (“Conteúdo”) serão
            consideradas de propriedade exclusiva da CONTRATANTE, sendo de sua responsabilidade
            manter uma cópia de todo o Conteúdo, sob sua integral responsabilidade, de modo que a
            MOBCALL está indene de quaisquer responsabilidades por perda do Conteúdo.
          </p>
          <p>
            <b>9.5</b> Autorização de Uso das Marcas. Sujeito aos termos e condições deste Contrato,
            a CONTRATANTE concede à MOBCALL, sem qualquer ônus, uma licença limitada, não exclusiva
            e não transferível para usar, reproduzir e veicular o nome, as marcas e demais sinais
            distintivos da CONTRATANTE, somente naquilo que seja estritamente necessário para
            cumprir as obrigações expressas neste Contrato. Não obstante a licença aqui concedida, o
            USUÁRIO permanecerá detentor de suas marcas, ficando a MOBCALL obrigada a não realizar
            qualquer ato inconsistente com esta titularidade.
          </p>
          <p class="font-bold pt-5">10. Confidencialidade</p>
          <p>
            <b>10.1</b> Durante a vigência deste Contrato, as Partes poderão ter acesso, direta ou
            indiretamente, em qualquer formato (oral ou escrito, seja em suporte físico ou digital),
            a diversas informações e documentos sigilosos da Parte contrária (“Parte Divulgadora”),
            contendo ou não a expressão Confidencial, ou qualquer designação similar, em virtude da
            Licença e do desempenho de Serviços e das atividades desenvolvidas no âmbito deste
            Contrato (“Informações Confidenciais”). A Parte que receber as Informações Confidenciais
            (“Parte Receptora”) obriga-se a manter as Informações Confidenciais sob absoluto sigilo,
            obrigando-se por si, por seus empregados, funcionários, prepostos, representantes,
            prestadores de serviço, consultores, executivos, conselheiros, acionistas, sócios,
            mandatários, parceiros, administradores, diretores e dirigentes (“Colaboradores”), a não
            as divulgar, sob qualquer forma, ou delas fazer qualquer uso comercial, sem o
            consentimento prévio e por escrito da Parte Divulgadora.
          </p>
          <p>
            <b>10.2</b> A Parte Receptora assume perante a Parte Divulgadora, em caráter irrevogável
            e irretratável, dentre outras obrigações de sigilo e confidencialidade estabelecidas
            neste Contrato, a obrigação de não fazer uso, não divulgar, não explorar, não revelar,
            não fornecer, não reproduzir, não publicar, não transmitir, não ceder, não facilitar e
            nem tornar acessível as Informações Confidenciais a terceiros, direta ou indiretamente,
            total ou parcialmente, exceto na forma autorizada por este Contrato. A Parte Receptora
            deverá proteger as Informações Confidenciais da Parte Divulgadora contra o uso ou
            revelação não autorizada, com o mesmo cuidado e proteção que utiliza para proteger suas
            próprias Informações Confidenciais. As Partes deverão também manter confidencialidade de
            todos os termos deste Contrato e de seus Anexos.
          </p>
          <p>
            <b>10.3</b> Informações Confidenciais. Serão consideradas “Informações Confidenciais”,
            para os fins deste Contrato, todas e quaisquer informações, orais ou escritas, de
            natureza técnica, operacional, financeira, comercial ou jurídica, incluindo, sem
            limitação, know-how, bancos de dados, modelos, fórmulas, amostras, teorias, patentes,
            propriedade intelectual, material, tecnologias, informações sobre fabricação e
            tecnologias de processo, fluxogramas, fotografias, ilustrações, imagens, dados pessoais,
            projetos, registros de projetos, listas de empregados, manuais de negócios, códigos de
            programação, programas de computador, linguagens (avançadas ou não), invenções, segredos
            industriais, segredos de negócio, condições financeiras, planos de desenvolvimento,
            identidades de co-desenvolvimento, lista e dados de clientes, lista de Colaboradores,
            histórico de ocorrências, ideias, estrutura, etapas de criação, de desenvolvimento e de
            implementação de projetos, planos de negócios, negócios potenciais, estratégias de
            negócios, registros de negócios, registros de mercado, políticas e diretrizes,
            procedimentos, conceitos de produtos e de serviços, métodos, técnicas, documentos,
            contratos de qualquer espécie, pareceres e pesquisas de qualquer espécie, enfim, toda e
            qualquer informação que a Parte Divulgadora disponibilize à Parte Receptora em relação
            aos seus negócios, operações, produtos, tecnologias e serviços, bem como de suas
            empresas controladoras, controladas ou coligadas.
          </p>
          <p>
            <b>10.4</b> Informações Não Confidenciais. Não serão consideradas Informações
            Confidenciais, para os propósitos deste Contrato, aquelas que comprovadamente: (i) já
            eram de conhecimento público antes de sua divulgação pela Parte Divulgadora; (ii) tenham
            chegado ao conhecimento público sem culpa ou dolo da Parte Receptora ou de seus
            Colaboradores; (iii) já eram conhecidas pela Parte Receptora antes da celebração deste
            Contrato; (iv) tornaram-se conhecidas pela Parte Receptora por meio de terceiros, desde
            que (a) a divulgação dessas informações não seja proibida por este Contrato e (b) a
            fonte de tais informações não esteja, segundo o melhor conhecimento da Parte Receptora,
            vinculada a qualquer acordo de confidencialidade; (v) informações que sejam
            independentemente desenvolvidas pela Parte Receptora, sem terem por base as Informações
            Confidenciais; e (vi) devam ser divulgadas, por determinação legal, ordem judicial ou
            requisição de autoridades competentes, sendo que a Parte Receptora, em tais casos,
            deverá notificar previamente a Parte Divulgadora acerca da existência e do conteúdo da
            determinação/ordem/requisição correspondente, em tempo razoável para que a Parte
            Divulgadora possa, caso deseje, tomar as medidas cabíveis ou apresentar suas
            contrarrazões perante o juízo ou autoridade competente – neste caso, a Parte Receptora
            compromete-se a divulgar tais Informações Confidenciais estritamente nos limites do que
            lhe for requisitado, bem como tomar todas as medidas necessárias para garantir que a
            autoridade que receba tais Informações Confidenciais determine segredo judicial ou
            confira tratamento confidencial adequado a tais informações.
          </p>
          <p>
            <b>10.5</b> Uso das Informações Confidenciais. A Parte Receptora somente utilizará as
            Informações Confidenciais para a execução das atividades necessárias envolvendo o objeto
            deste Contrato, comprometendo-se a não as utilizar para qualquer outra finalidade,
            direta ou indiretamente, exceto se expressamente previsto neste Contrato ou se
            autorizada de forma prévia e por escrito pela Parte Divulgadora. A Parte Receptora
            obriga-se a utilizar as Informações Confidenciais de forma proba, diligente e razoável,
            estritamente de acordo com as orientações da Parte Divulgadora, tão somente para atingir
            os fins especificamente necessários dentro do âmbito da relação entre as Partes. A Parte
            Receptora compromete-se, ainda, a envidar seus melhores esforços para proteger as
            Informações Confidenciais contra qualquer divulgação não autorizada.
          </p>
          <p>
            <b>10.6</b> Divulgação aos Colaboradores. A Parte Receptora compromete-se a divulgar as
            Informações Confidenciais apenas aos seus Colaboradores diretamente envolvidos nas
            discussões e nas atividades vinculadas à execução deste Contrato e somente naquilo que
            for estritamente necessário para que tais Colaboradores realizem seus trabalhos,
            devendo, ainda, assegurar, mediante acordo escrito, que os Colaboradores que tiverem
            acesso às Informações Confidenciais respeitem os termos desta cláusula de
            confidencialidade, guardando sigilo sobre as Informações Confidenciais que receberem e
            não as divulgando para quaisquer terceiros, respondendo a Parte Receptora por todo e
            qualquer uso e divulgação não autorizados das Informações Confidenciais realizados por
            seus Colaboradores.
          </p>
          <p>
            <b>10.7</b> Todas as Informações Confidenciais que a Parte Receptora tiver acesso
            permanecerão sendo de exclusiva propriedade da Parte Divulgadora. A Parte Receptora
            aceita e concorda que não possui e nem possuirá quaisquer direitos sobre as Informações
            Confidenciais da Parte Divulgadora, sendo as respectivas Informações Confidenciais de
            propriedade exclusiva da Parte Divulgadora. Nenhuma cláusula deste Contrato será
            interpretada como cessão de qualquer direito pertinente às Informações Confidenciais.
          </p>
          <p>
            <b>10.8</b> Devolução ou Destruição das Informações Confidenciais. Caso seja solicitado
            pela Parte Divulgadora, a qualquer tempo, ou em caso de término da relação entre as
            Partes, independentemente da causa, a Parte Receptora destruirá ou restituirá à Parte
            Divulgadora, a exclusivo critério desta, todas as cópias, bancos de dados, reproduções
            e/ou adaptações que porventura tiver realizado das Informações Confidenciais, incluindo
            todas as anotações, descrições, resumos, rascunhos e materiais envolvendo as Informações
            Confidenciais ou nelas baseados, no prazo razoável requerido pela Parte Divulgadora.
          </p>
          <p>
            <b>10.9</b> Incidentes. Caso a Parte Receptora tome conhecimento ou suspeite de qualquer
            acontecimento que viole as regras desta cláusula ou coloque em risco a segurança das
            Informações Confidenciais da Parte Divulgadora, a Parte Receptora deverá imediatamente
            comunicar a Parte Divulgadora.
          </p>
          <p>
            <b>10.10</b> Responsabilidade. A Parte Receptora será a única e a exclusiva responsável
            pelo uso que fizer das Informações Confidenciais, por si ou por seus Colaboradores. O
            eventual uso indevido, por negligência, imprudência, imperícia ou até mesmo intencional
            que a Parte Receptora e os Colaboradores fizerem das Informações Confidenciais será de
            sua exclusiva responsabilidade, de tal forma que a Parte Receptora ficará obrigada a
            indenizar a Parte Divulgadora pelos prejuízos causados.
          </p>
          <p>
            <b>10.11</b> Indenização. A constatação de qualquer infração das obrigações previstas
            nesta cláusula, cometida pela Parte Receptora ou por seus Colaboradores, sujeitará a
            Parte Receptora ao pagamento de indenização pelas perdas e danos a que der causa, sem
            prejuízo da aplicação de toda e qualquer sanção cível ou penal prevista na legislação
            brasileira.
          </p>
          <p>
            <b>10.12</b> Prazo. As obrigações e proibições previstas nesta cláusula permanecerão
            válidas durante a vigência deste Contrato e pelo período adicional de 5 (cinco) anos
            contados do seu término, independentemente do motivo, ressalvados os casos em que a
            obrigação de confidencialidade sobreviverá ao prazo aqui estipulado em razão da natureza
            da Informação Confidencial, por exemplo, nas hipóteses envolvendo segredo empresarial e
            dados pessoais.
          </p>
          <p class="font-bold pt-5">11. Dados Pessoais</p>
          <p>
            <b>11.1</b> A proteção e tratamento de dados pessoais ocorridas em decorrência do objeto
            do presente Contrato serão regidas pelas disposições da Política de Privacidade Mobcall,
            que constitui parte integrante do presente Contrato como “Anexo III – Política de
            Privacidade e Tratamento de Dados Pessoais”.
          </p>
          <p class="font-bold pt-5">12. Disposições Gerais</p>
          <p>
            <b>12.1</b> Integram como parte indissociável do presente Contrato para todos os fins e
            efeitos, como se aqui estivessem transcritos, os seguintes Anexos, estipulando-se que em
            caso de divergência entre as disposições dos Anexos e do Contrato, prevalecerá o
            disposto nos Anexos:
          </p>
          <p class="pl-16 relative font-bold">
            <span class="absolute left-7">(i)</span> Anexo I – Ordem de Serviço
          </p>
          <p class="pl-16 relative font-bold">
            <span class="absolute left-7">(ii)</span> Anexo II – SLA
          </p>
          <p class="pl-16 relative font-bold">
            <span class="absolute left-7">(iii)</span> Anexo III – Política de Privacidade e
            Tratamento de Dados Pessoais
          </p>
          <p>
            <b>12.2</b> Todos os tributos e encargos fiscais devidos em razão deste Contrato já
            estão incluídos no valor da Remuneração e serão de exclusiva responsabilidade de
            recolhimento do contribuinte definido pela legislação tributária.
          </p>
          <p>
            <b>12.3</b> A extinção deste Contrato, independentemente de seu motivo ou forma, não
            isentará qualquer das Partes do cumprimento das obrigações de caráter perene, incluindo,
            sem limitação, as de indenização, confidencialidade e proteção de dados.
          </p>
          <p>
            <b>12.4</b> Sem prejuízo de qualquer outra limitação prevista neste Contrato e/ou na
            legislação aplicável, a MOBCALL não será responsável pelo conteúdo de quaisquer
            ligações, e-mails, mensagens, anúncios e/ou atendimentos realizados pela CONTRATANTE e
            atendentes de sua empresa aos seus clientes. A MOBCALL não garante, endossa, assegura ou
            assume responsabilidade por qualquer produto ou serviço divulgado ou oferecido pela
            CONTRATANTE ou por algum terceiro nas ligações e/ou atendimentos realizados pela
            CONTRATANTE. Em hipótese alguma, a MOBCALL será responsável por quaisquer multas, lucros
            cessantes ou perdas e danos, diretos e indiretos, decorrentes de qualquer aspecto do uso
            de Serviços que sejam decorrentes de ação ou omissão exclusiva da CONTRATANTE.
          </p>
          <p>
            <b>12.5</b> As Partes declaram-se ciente e comprometem-se, direta e indiretamente, por
            seus empregados, representantes, administradores, diretores, conselheiros, afiliadas,
            sócios ou acionistas, assessores, consultores ou parte relacionada, a observar e a
            cumprir rigorosamente todas as leis aplicáveis contra práticas de suborno e corrupção,
            incluindo, mas não se limitando a (i) atual legislação brasileira de anticorrupção (Lei
            n. 12.846/2013) e as demais leis que forem aprovadas e que regulamentarem essa mesma
            matéria; o (ii) Foreign Corrupt Practices Act (FCPA); o (iii) UK Bribery Act; e (iv)
            todas as convenções e pactos internacionais dos quais o Brasil seja ou será signatário,
            tais como a OECD Convention on Combating Bribery of Foreign Public Officials in
            International Business Transactions e a UN Convention Against Corruption, todas
            denominadas, conjuntamente, de “Normas Anticorrupção”). As Partes comprometem-se a (i)
            adotar as melhores práticas de ética e integridade, bem como controles internos, visando
            prevenir atos de corrupção, fraude, práticas ilícitas ou lavagem de dinheiro; (c)
            abster-se de praticar atos que violem as Normas Anticorrupção e de agir de forma lesiva
            à administração pública, no interesse ou para benefício próprio, da Parte contrária ou
            de terceiros; e (iii) caso tenha conhecimento de qualquer ato ou fato que viole as
            normas citadas, comunicar imediatamente a Parte contrária.
          </p>
          <p>
            <b>12.6</b> <u>Acordo Integral.</u> Este Contrato e seus Anexos refletem a íntegra dos
            entendimentos e acordos assumidos entre as Partes em relação ao seu objeto. Sendo assim,
            este Contrato e seus Anexos revogam e substituem qualquer entendimento, acordo ou outro
            termo, verbal ou escrito, celebrado anteriormente à assinatura deste Contrato que se
            refira ao mesmo objeto aqui disposto.
          </p>
          <p>
            <b>12.7</b> <u>Alteração.</u> Este Contrato poderá ser alterado, aditado ou revisto, a
            qualquer momento, a exclusivo critério da MOBCALL. A MOBCALL enviará uma notificação à
            CONTRATANTE informando sobre a alteração, e, caso a CONTRATANTE não concorde com as
            novas condições do Contrato, deverá informar à MOBCALL sobre sua discordância no prazo
            de 5 (cinco) dias contados do recebimento da notificação, hipótese em que a versão
            anterior do Contrato permanecerá em vigor até o término da vigência da contratação
            estabelecida na Cláusula 4.1 deste Contrato. A CONTRATANTE reconhece que a renovação da
            Licença após o recebimento da notificação informando sobre a alteração das provisões do
            Contrato implicará no aceite da última versão do Contrato.
          </p>
          <p>
            <b>12.8</b> <u>Efeito Vinculante.</u> Este Contrato vincula as Partes e seus sucessores,
            a qualquer título, ao fiel cumprimento deste, em caráter irrevogável e irretratável.
          </p>
          <p>
            <b>12.9</b> <u>Tolerância.</u> A tolerância de qualquer das Partes com relação à
            exigência do regular e tempestivo cumprimento das obrigações da outra Parte não
            constituirá desistência, alteração, modificação ou novação de quaisquer dos direitos ou
            obrigações estabelecidos por meio deste Contrato, constituindo mera liberdade, que não
            impedirá a Parte tolerante de exigir da outra o fiel e cabal cumprimento deste Contrato,
            a qualquer tempo.
          </p>
          <p>
            <b>12.10</b> <u>Renúncia.</u> Nenhuma renúncia ao exercício de direito assegurado nestes
            será válida, exceto se formalizada por escrito pela Parte renunciante.
          </p>
          <p>
            <b>12.11</b> <u>Invalidade Parcial.</u> A invalidade parcial deste Contrato não o
            afetará na parte considerada válida, desde que as obrigações sejam desmembráveis entre
            si. Ocorrendo o disposto nesta cláusula, as Partes desde já se comprometem a negociar,
            no menor prazo possível, em substituição à cláusula invalidada, a inclusão de termos e
            condições válidos que reflitam os termos e condições da cláusula invalidada, observado o
            objetivo e a intenção das Partes quando da negociação da cláusula invalidada e o
            contexto em que se insere.
          </p>
          <p>
            <b>12.12</b> <u>Cessão.</u> Este Contrato não poderá ser cedido pela CONTRATANTE, no
            todo ou em parte, salvo mediante expresso consentimento por escrito da MOBCALL. Fica
            desde já acertado que a MOBCALL poderá, a qualquer momento, ceder os direitos e
            obrigações decorrentes deste Contato, total ou parcialmente, a quaisquer terceiros,
            mediante simples comunicação por escrito à CONTRATANTE.
          </p>
          <p>
            <b>12.13</b> <u>Autonomia das Partes.</u> As Partes declaram, para todos os efeitos, que
            são independentes e autônomas, de forma que este Contrato não cria qualquer outra
            modalidade de vínculo entre ambas, inclusive, sem limitação, mandato, sociedade,
            associação, parceria, consórcio, joint-venture ou representação comercial. Cada Parte é
            totalmente responsável por seus atos e obrigações assumidos por meio deste Contrato.
            Salvo nos casos expressamente previstos neste Contrato, as Partes reconhecem que elas
            não têm qualquer autoridade ou poder para, direta ou indiretamente, negociar, contratar,
            assumir qualquer tipo de obrigação ou criar responsabilidade em nome da outra Parte, sob
            hipótese alguma.
          </p>
          <p>
            <b>12.14</b> <u>Desvinculação Trabalhista.</u> As Partes reconhecem não existir qualquer
            vínculo de natureza trabalhista e/ou de subordinação jurídica e econômica entre as
            Partes, que seja relacionado a presente contratação, bem como entre seus empregados e/ou
            prestadores de serviços com a Parte contrária, assumindo cada Parte integral
            responsabilidade pelos encargos trabalhistas, securitários, acidentários e
            previdenciários de toda a mão de obra por ela contratada para execução do objeto deste
            Contrato.
          </p>
          <p>
            <b>12.15</b> <u>Indenização.</u> Cada Parte (“Parte Indenizadora”) deverá defender,
            isentar e indenizar a outra Parte (“Parte Indenizada”) contra todas e quaisquer perdas e
            danos, diretos e indiretos, causados à Parte Indenizada, oriundos de qualquer ação ou
            omissão comprovadamente praticados pela própria Parte Indenizadora, em violação às
            disposições do (i) deste Contrato e seus Anexos; e/ou (ii) da legislação em vigor
            aplicável, incluindo, sem limitação, valores despendidos com o pagamento de condenações,
            judiciais e administrativas, acordos (desde que previamente autorizados pela Parte
            Indenizadora), termos de ajustamento de conduta (TAC), custas e despesas, judiciais e
            extrajudiciais, tais como, mas sem limitação, honorários periciais, honorários
            advocatícios sucumbenciais e contratados – neste último caso, desde que razoáveis, entre
            outros. Caso seja devida qualquer indenização pela MOBCALL à CONTRATANTE, em razão de
            danos comprovadamente causados pela MOBCALL, o valor da indenização será limitado aos
            danos diretos incorridos e ao valor total recebido, pela MOBCALL da CONTRATANTE, nos 12
            (doze) meses imediatamente anteriores ao incidente que deu causa à tal indenização,
            conforme estipulado neste Contrato.
          </p>
          <p>
            <b>12.16</b> <u>Notificações.</u> Todas as notificações e comunicações exigidas ou
            permitidas neste Contrato deverão ser enviadas por escrito e entregues a cada Parte, com
            confirmação de recebimento, nos endereços informados na Ordem de Serviço. Incumbe às
            Partes o dever de manter devidamente atualizadas a suas informações para envio de
            notificações, sob pena de ser considerada entregue uma notificação encaminhada para
            endereço não atualizado.
          </p>
          <p>
            <b>12.17</b> <u>Mútuo Acordo.</u> As Partes declaram e reconhecem que entenderam
            perfeitamente o sentido e o alcance de todas as cláusulas e condições convencionadas
            neste Contrato, obrigando-se a cumpri-las fielmente em atenção aos princípios da boa-fé
            e lealdade, abstendo-se de invocar, no futuro, ressalvas, oposições ou exceções que
            impliquem em modificação das convenções ora celebradas ou que pretendam interpretá-las
            de forma diversa daquela literalmente convencionada.
          </p>
          <p>
            <b>12.18</b> <u>Garantias.</u> Todas as garantias oferecidas pela MOBCALL estão
            expressamente previstas neste Contrato. Nenhuma recomendação, declaração, conselho,
            comentário ou informação, oral ou escrita, obtida da MOBCALL ou por meio da execução
            deste Contrato que não estejam expressamente previstas neste Contrato deverão ser
            consideradas ou criarão qualquer garantia.
          </p>
          <p>
            <b>12.19</b> <u>Ofertas e Promoções.</u> Ao aceitar este Contrato, a CONTRATANTE
            reconhece e concorda que poderá receber em seu endereço de e-mail ofertas, comunicados,
            anúncios, promoções, entre outros, da MOBCALL e de empresas parceiras. Caso não deseje
            receber este tipo de comunicação, a CONTRATANTE deverá comunicar a MOBCALL por meio do
            e-mail
          </p>
          <p>
            <b>12.20</b> As Partes concordam que o presente Contrato poderá ser assinado
            digitalmente, nos termos da Lei 13.874, de 20 de setembro de 2019 (“Lei 13.874/19”), bem
            como da Medida Provisória 2.200-2 de 24 de agosto de 2001 (“MP 2.200-2”), do Decreto
            10.278, de 18 de março de 2020 (“Decreto 10.278/20”), e, ainda, no Enunciado nº 297 do
            Conselho Nacional de Justiça. Para este fim, serão utilizados os serviços disponíveis no
            mercado e amplamente utilizados que possibilitam a segurança, validade jurídica,
            autenticidade, integridade e validade da assinatura eletrônica por meio de sistemas de
            certificação digital capazes de validar a autoria, bem como de traçar a “trilha de
            auditoria digital” (cadeia de custódia) do documento, a fim de verificar sua integridade
            e autenticidade. Dessa forma, a assinatura física de documentos, bem como a existência
            física (impressa), de tais documentos não serão exigidas para fins de cumprimento de
            obrigações previstas neste Contrato, exceto se outra forma for exigida por órgãos
            competentes, hipótese em que as Partes se comprometem a atender eventuais solicitações
            no prazo de 5 (cinco) dias úteis, a contar da data da exigência.
          </p>
          <p>
            <b>12.21</b> <u>Lei Aplicável e Foro.</u> Este Termo será regido e interpretado de
            acordo com as leis da República Federativa do Brasil. As Partes elegem o Foro da Comarca
            de Barueri, Estado de São Paulo, para dirimir todas as questões dele oriundas, com
            exclusão de qualquer outro, por mais privilegiado que seja.
          </p>
        </div>

        <div class="space-y-4 text-sm pb-10">
          <p class="font-semibold text-gray-800">
            Anexo II – Acordo de Nível de Serviço ou service level agreement (“SLA”)
          </p>
          <p>
            <b>1.1</b> O presente Acordo de Nível de Serviço ou service level agreement (“SLA”)
            integra o Contrato de Licença de Uso de Software Como Serviço e Prestação de Serviços
            celebrado entre a CONTRATANTE e a MOBCALL (“Contrato”) como Anexo II, sendo parte
            indissociável do Contrato.
          </p>
          <p>
            <b>1.1.1</b> Denomina-se SLA, para efeito do presente Contrato de Licença de Uso de
            Software Como Serviço e Prestação de Serviços (“Contrato”), o nível de desempenho
            técnico do serviço prestado proposto pela MOBCALL, sendo certo que tal acordo não
            representa diminuição de responsabilidade da MOBCALL, mas sim indicador de excelência
            técnica, uma vez que em informática não existe garantia integral (100%) de nível de
            serviço.
          </p>
          <p>
            <b>1.2</b> A MOBCALL, desde que observadas as obrigações a cargo da CONTRATANTE
            previstas no presente Contrato, tem condição técnica de oferecer e se propõe a manter,
            mensalmente, um SLA variável de acordo com o pacote de serviços contratado pela
            CONTRATANTE.
          </p>
          <p>
            <b>1.3</b> Serviços Mobcall. A MOBCALL disponibiliza serviços conforme previstos na
            Ordem de Serviços e no detalhamento que segue, oferecendo diversas funcionalidades
            atreladas ao(s) serviço(s) contratado(s) pela CONTRATANTE.
          </p>
          <p>
            <b>1.3.1</b> PABX Virtual/Número Fixo ou Móvel – Nacional ou Internacional. O recurso
            PABX Virtual /Número permite que o CONTRATANTE tenha acesso a um número de telefone de
            acordo com a contratação (fixo ou móvel, nacional ou internacional) que possibilita
            realizar e receber ligações. A contratação do PABX/Número também disponibiliza à
            CONTRATANTE o acesso a um ramal único e individual para ligações internas, que não
            possuem ônus. A CONTRATANTE receberá, para cada PABX/Número, acesso individual e logado
            ao Softphone Mobcall, por meio do qual será possível realizar e receber ligações. O
            acesso ao Softphone Mobcall poderá ser feito via celular, browser ou aplicativo
            instalado em computador, com logs não simultâneos. A CONTRATANTE terá acesso individual
            e logado ao Connect Mobcall, por meio do qual poderá acompanhar as ligações e atividades
            de telefonia em tempo real, além de ter acesso a diversos relatórios relacionados. A
            CONTRATANTE terá as chamadas gravadas e armazenada no Connect, com as opções “ouvir” e
            “download”. As ligações realizadas ou recebidas por meio de um PABX/Número Mobcall serão
            transcitas, possibilitando à CONTRATANTE a busca por palavra nas ligações e o cadastro
            prévio de palavras e termos compostos relevantes e de interesse. A contratação do
            PABX/Número possibilita, ainda: configuração de atendimento automático, configuração de
            equipes, atendimento de chamadas de outros PABX/Números/Ramais, transferência assistida
            ou cega, realização de conferncia com até 3 usuários e a implantação de pesquisa de
            satisfação automática. A CONTRATANTE estará sujeita à cobrança de minutagem de acordo
            com a Ordem de Serviço.
          </p>
          <p>
            <b>1.3.2.</b> Ramal. O recurso Ramal permite que a CONTRATANTE tenha acesso a um ramal
            com possibilidade de realizar e receber ligações internas e receber ligações
            direcionadas a partir de uma URA de Atendimento (fila). A CONTRATANTE receberá, para
            cada Ramal, acesso individual e logado ao Softphone Mobcall, por meio do qual será
            possível realizar e receber ligações internas. O acesso ao Softphone Mobcall poderá ser
            feito via celular, browser ou aplicativo instalado em computador, com logs não
            simultâneos. A CONTRATANTE terá acesso individual e logado ao Connect Mobcall, por meio
            do qual poderá acompanhar as ligações e atividades de telefonia em tempo real, além de
            ter acesso a diversos relatórios relacionados. A CONTRATANTE terá as chamadas gravadas e
            armazenada no Connect, com as opções “ouvir” e “download”. As ligações realizadas ou
            recebidas por meio de um Ramal Mobcall serão transcitas, possibilitando à CONTRATANTE a
            busca por palavra nas ligações e o cadastro prévio de palavras e termos compostos
            relevantes e de interesse. A contratação do Ramal possibilita, ainda: configuração de
            atendimento automático, configuração de equipes, atendimento de chamadas de outros
            PABX/Números/Ramais, transferência assistida ou cega, realização de conferncia com até 3
            usuários e a implantação de pesquisa de satisfação automática. A CONTRATANTE estará
            sujeita à cobrança de minutagem de acordo com a Fatura de Serviços.
          </p>
          <p>
            <b>1.3.3.</b> Call Tracking Nacional/Internacional Fixo/Móvel/0800/400X. O recurso Call
            Tracking permite que a CONTRATANTE tenha acesso a um número de telefone
            Nacional/Internacional Fixo/Móvel/0800/400x, de acordo com o descrito na Ordem de
            Serviço, de máscara única, com função receptiva. A CONTRATANTE receberá acesso único e
            logado ao App Mobcall para acompanhamento de ligações e relatórios, com opção de
            exportação. A CONTRATANTE terá as chamadas gravadas e armazenada no App Mobcall, com as
            opções “ouvir” e “download”. As ligações recebidas por meio de Call Tracking Mobcall
            serão transcitas, possibilitando à CONTRATANTE a busca por palavra nas ligações e o
            cadastro prévio de palavras e termos compostos relevantes e de interesse. A contratação
            do Call Traking possibilita, ainda: disponibilização de script para implantação do
            número em mídia/acompanhamento de retorno, redirecionamento para de 1 a 9 números e a
            criação de novos usuários ao App Mobcall sem ônus. A CONTRATANTE estará sujeita à
            cobrança de minutagem de acordo com a Ordem de Serviço.
          </p>
          <p>
            <b>1.3.4.</b> Serviço de Discador. O recurso de Discador permite que a CONTRATANTE tenha
            acesso a um número de telefone fixo configurado como Discador Ativo, Discador Receptivo
            ou Discador Preditivo, de acordo com as especificações do projeto. O Serviço de Discador
            poderá usar a saída de Telefonia da Mobcall com minutagem aplicável adicional ou ser
            configurado em outras operadoras de voz, de acordo com a Ordem de Serviço. A CONTRATANTE
            receberá um ramal único e individual para ligações internas, sob as quais não incidirá
            cobrança de minutagem. A CONTRATANTE receberá, para cada Usuário de Discador, acesso
            individual e logado ao Softphone Mobcall, por meio do qual será possível realizar e
            receber ligações. O acesso ao Softphone Mobcall poderá ser feito exclusivamente via
            browser. A CONTRATANTE poderá acompanhar as ligações e atividades de telefonia em tempo
            real, além de ter acesso a diversos relatórios relacionados. A CONTRATANTE terá as
            chamadas gravadas e armazenada no Connect, com as opções “ouvir” e “download”. As
            ligações realizadas ou recebidas por meio de um Discador Mobcall serão transcitas,
            possibilitando à CONTRATANTE a busca por palavra nas ligações e o cadastro prévio de
            palavras e termos compostos relevantes e de interesse. A contratação do Discador
            possibilita, ainda: configuração de atendimento automático, configuração de equipes,
            atendimento de chamadas de outros Discadores, transferência assistida ou cega,
            realização de conferência com até 3 usuários, implantação de pesquisa de satisfação
            automática, criação e gestão de campanhas, importação e gerenciamento de mailing,
            detecção automática de caixa postal, qualificação/tabulação das chamadas pelo operador,
            configuração de prioridade de chamadas, configuração de lista negativa/números
            bloqueados, gerenciamento de regras de negócio/funcionamento, agendamento de ligações,
            configuração de Horário de Atendimento, gerenciamento de persistência vs status e
            configuração de screen pop up. A CONTRATANTE estará sujeita à cobrança de minutagem de
            acordo com a Ordem de Serviço.
          </p>
          <p>
            <b>1.3.5.</b> URA/Fila de Atendimento. O recurso URA/Fila de Atendimento permite que a
            CONTRATANTE tenha acesso a um número número virtual local fixo para recepção das
            ligações. A MOBCALL fará a configuração da URA conforme a necessidade do cliente, com
            uso de voz artificial ou importação de áudio do cliente para URA. A CONTRATANTE terá
            acesso à configuração e à automação de fila de atendimento (direcionamento automático de
            ligações), à configuração de horário de atendimento na URA, contará com a possibilidade
            de inclusão de mensagem personalizada de "fora de horário" com voz artificial ou
            importação de áudio e terá à sua disposição a possibilidade de configuração de menu de
            opções de atendimento para o cliente. A CONTRATANTE terá acesso individual e logado ao
            Connect Mobcall, por meio do qual poderá acompanhar as ligações e atividades de
            telefonia em tempo real, além de ter acesso a diversos relatórios relacionados. A
            CONTRATANTE terá as chamadas gravadas e armazenada no Connect, com as opções “ouvir” e
            “download”. As ligações realizadas ou recebidas por meio de uma URA Mobcall serão
            transcitas, possibilitando à CONTRATANTE a busca por palavra nas ligações e o cadastro
            prévio de palavras e termos compostos relevantes e de interesse. A CONTRATANTE poderá,
            ainda configurar e implantar uma pesquisa de satisfação.
          </p>
          <p>
            <b>1.3.6.</b> Speech Analytics. O recurso de Speech Analytics permite à CONTRATANTE a o
            acesso, via plataforma segura com URL e informações de login e senha exclusivos para o
            projeto, de dashboards com visões exportáveis de Speech Analytics. O serviço de Speech
            Analytics terá sua entrega definida conforme escopo do projeto e está sujeito à cobrança
            de setup, conforme definido em Ordem de Serviço. Todas as ligações serão transcritas por
            meio de processo automatizado e as palavras serão isoladas, permitindo a criação de
            nuvens de palavras para consultas. O serviço de speech analytics prevê: criação de
            plugin para as ligações, cálculo de tempo de atendimento, cálculo de tempo de silêncio e
            non talk, busca de palavras e frases no inventário de ligações, filtro de ligações a
            partir de palavras ou trechos de transcrição e busca de palavras e frases dentro da
            ligação (plug in). O serviço de Speech Analytics possibilita ainda, de acordo com o
            escopo definido: cadastro de palavras e termos compostos para destaque no plug in,
            construção de API para Importação de metadados a partir de repositório em nuvem, criação
            de ambiente seguro para repositório em nuvem, tratamento de áudio (melhoria da qualidade
            dos áudios, cancelamento de ruídos, melhoria em distorção de fala, redução de ambiente
            de reverberação), identificação de transição de sentimento, cálculo de tendência de NPS
            e marcações customizadas nas ligações para identificação de pontos relevantes do
            negócio.
          </p>
          <p>
            <b>1.3.7.</b> Text Analytics. O recurso de Text Analytics permite à CONTRATANTE a o
            acesso, via plataforma segura com URL e informações de login e senha exclusivos para o
            projeto, de dashboards com visões exportáveis de Text Analytics. O serviço de Text
            Analytics terá sua entrega definida conforme escopo do projeto e está sujeito à cobrança
            de setup, conforme definido em Ordem de Serviço. Todas as conversas de texto serão
            processadas por meio de processo automatizado e as palavras serão isoladas, permitindo a
            criação de nuvens de palavras para consultas. O serviço de text analytics prevê: criação
            de plugin para as conversas, cálculo de tempo de atendimento, busca de palavras e frases
            no inventário de conversas, filtro de conversas a partir de palavras ou trechos de
            transcrição e busca de palavras e frases dentro da conversas(plug in). O serviço de Text
            Analytics possibilita ainda, de acordo com o escopo definido: cadastro de palavras e
            termos compostos para destaque no plug in, construção de API para Importação de
            metadados a partir de repositório em nuvem, criação de ambiente seguro para repositório
            em nuvem, identificação de sentimento do cliente e marcações customizadas nas conversas
            para identificação de pontos relevantes do negócio.
          </p>
          <p>
            <b>1.3.8.</b> Usuário de Vídeo Conferência. O recurso de Usuário de Vídeo Conferência
            permite que a CONTRATANTE tenha acesso a um usuário único e individual que possibilita a
            realização e a recepção de chamadas em vídeo. A CONTRATANTE receberá, para cada Usuário,
            acesso individual e logado ao Softphone Mobcall, por meio do qual será possível realizar
            e receber chamadas de vídeo conferência. O acesso ao Softphone Mobcall poderá ser feito
            via browser. A CONTRATANTE terá acesso individual e logado ao Connect Mobcall, por meio
            do qual poderá acompanhar as ligações de vídeo conferência e atividades de vídeo em
            tempo real, além de ter acesso a diversos relatórios relacionados. A CONTRATANTE terá as
            chamadas gravadas e armazenada como arquivo local na máquina do Usuário. A contratação
            do Usuário de Vídeo Conferência possibilita, ainda: configuração de equipes, realização
            de conferência de vídeo com 2 (dois) ou mais usuários, limitados a 30 (trinta) usuários.
          </p>
          <p>
            <b>1.3.9.</b> Gravação de Vídeo Conferência em Cloud. O recurso de Gravação de Vídeo
            Conferência em Cloud somente pode ser adquirido caso a CONTRATANTE obtenha o serviço de
            Usuário de Vídeo Conferência. O serviço de Gravação possibilita a gravação das vídeo
            conferências realizadas na nuvem MOBCALL. A gravação se dá de forma automática, com
            funções “assistir” e “download”. A contratação da Gravação de Vídeo Conferência
            possibilita, ainda: transcrição das ligações de vídeo e busca por palavra por ligação e
            cadastro de palavras e termos compostos relevantes para o negócio, para tagueamento em
            ligações de vídeo.
          </p>
          <p>
            <b>1.4.</b> Em relação às funcionalidades de Serviços que incluem sistemas de gravação e
            monitoramento de chamadas telefônicas, vídeo chamadas, e-mails e mensagens, a
            CONTRATANTE declara e concorda que a MOBCALL não possui qualquer responsabilidade em
            relação ao conteúdo das conversas tratadas entre os sócios, administradores, empregados,
            atendentes e/ou representantes da CONTRATANTE e os seus clientes. A CONTRATANTE
            compromete-se em seguir todos os procedimentos de atendimento ao consumidor exigidos na
            lei aplicável e tomar todos os cuidados e precauções a fim garantir os direitos do
            consumidor, informando ao consumidor se a conversa será gravada e os fins para os quais
            a gravação será utilizada. A MOBCALL recomenda que as gravações sejam arquivadas pela
            CONTRATANTE pelo prazo mínimo de 90 (noventa) dias, durante o qual o seu cliente poderá
            requerer acesso à gravação. Caso haja o requerimento, a MOBCALL recomenda o envio da
            gravação no prazo máximo de 72 (setenta e duas) horas.
          </p>
          <p>
            <b>1.4.1.</b> Com relação à função de gravação de chamadas, a CONTRATANTE entende que no
            início das chamadas os seus clientes deverão ouvir uma gravação inicial informando,
            entre outros itens, que a chamada está sendo gravada. Além disso, a CONTRATANTE entende
            e concorda que deve informar a todos os seus Colaboradores que as chamadas realizadas ou
            recebidas por eles via Mobcall estão sendo gravadas. A CONTRATANTE deve assegurar que
            essas gravações e notificações estão em conformidade com todas as leis, regulamentos e
            práticas relevantes e/ou aplicáveis à CONTRATANTE e ao seu negócio.
          </p>
          <p>
            <b>1.4.2.</b> Todas as gravações, bem como todo o conteúdo originado das gravações,
            análises, diagnósticos, monitoramentos, relatórios, e resultados criados no âmbito de
            utilização de Serviços, devem ser armazenados pela CONTRATANTE em aparato tecnológico de
            sua escolha, não se responsabilizando a MOBCALL pela perda desse conteúdo, total ou
            parcialmente.
          </p>
          <p>
            <b>1.5.</b> Nível de Serviço. A MOBCALL garante que os serviços estarão disponíveis 24
            (vinte e quatro) horas por dia, 7 (sete) dias por semana, incluindo feriados,
            apresentando plenas condições de acesso, navegação e funcionamento durante, no mínimo,
            94,7% (noventa e quatro vírgula sete por cento) do tempo, exceto em casos de paradas
            programadas ou emergenciais que serão realizadas para manter, atualizar ou ajustar as
            configurações de Serviços. Não se aplica o disposto nesta cláusula às falhas de acesso,
            navegação, funcionamento, visualização, atualização ou carregamento de Serviços,
            incluindo atrasos, interrupções, instabilidades, e indisponibilidade, decorrentes de
            circunstâncias que estejam fora do controle da MOBCALL, tais como, sem limitação:
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(i)</span> falhas decorrentes de um evento inesperado,
            caso fortuito ou força maior;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(ii)</span> falhas de acesso à internet, quedas de
            energia, mau funcionamento eletrônico e/ou físico de qualquer rede de telecomunicações,
            interrupções ou suspensões de conexão;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(iii)</span> falhas em software e/ou hardware da
            CONTRATANTE e/ou dos Usuários Finais de Serviços;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(iv)</span> falhas em software e/ou hardware da
            CONTRATANTE e/ou dos Usuários Finais de Serviços;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(v)</span> falhas decorrentes da prestação de serviços de
            terceiros, que sejam necessários para o pleno funcionamento de Serviços e que estejam
            fora do controle da MOBCALL, visto que esta não tem qualquer poder de gerência ou
            responsabilidade sobre tais serviços;
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(vi)</span> invasão de hackers, crakers e/ou de programas
            de computador desenvolvidos com o objetivo de causar algum dano na infraestrutura e/ou
            no ambiente computacional da CONTRATANTE e/ou dos Usuários Finais (ex. vírus, malware,
            cavalos de troia, etc)
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(vii)</span> atos ou omissões da CONTRATANTE e/ou dos
            Usuários Finais e/ou de terceiros, incluindo, sem limitação, o mau uso de Serviços ou
            uso em desconformidade com as orientações da MOBCALL, erros ou omissões nos dados e
            informações inseridas na Plataforma ou nos serviços prestados pela MOBCALL; e
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(viii)</span> determinações autoridades públicas que
            impactem nos serviços.
          </p>
          <p>
            <b>1.6.</b> Suporte Técnico. A MOBCALL oferecerá serviços de suporte técnico à
            CONTRATANTE (“Suporte Técnico”), que incluem o oferecimento de (i) de diretrizes,
            esclarecimentos e resolução de dúvidas sobre o uso de Serviços; e (ii) de diretrizes e
            soluções gerais para problemas técnicos relacionados aos Serviços. Tendo em vista que os
            Serviços são oferecidos na modalidade online, fica expressamente excluído do Suporte
            Técnico oferecido pela Suporte Técnico todo e qualquer suporte relativo à infraestrutura
            da CONTRATANTE e dos Usuários Finais, bem como de outros programas de computador sob
            responsabilidade de terceiros.
          </p>
          <p>
            <b>1.6.1.</b> O Suporte Técnico será realizado pelo time de atendimento da MOBCALL, por
            meio do e-mail suporte@mobcall.com ou telefone 0800 038 5836, de segunda a sexta feira,
            das 09h00 às 18h00, exceto feriados nacionais, estaduais (SP) e municipais (Capital). As
            atividades de Suporte Técnico serão realizadas por acesso remoto online ou pela
            disponibilização de manuais online em formatos multimídia. Apenas em casos de
            indisponibilidade de Serviços, a MOBCALL oferecerá serviço de plantão em qualquer hora
            do dia e semana por meio do telefone 0800 038 5836.
          </p>
          <p>
            <b>1.6.2.</b> O primeiro retorno da MOBCALL aos chamados relativos a dúvidas, enviados
            pela CONTRATANTE dentro do horário comercial, serão respondidos dentro dos prazos
            máximos descritos na tabela abaixo em pelo menos 80% (oitenta por cento) dos pedidos.
            Tais prazos irão variar de acordo com a classificação da criticidade da questão
            apresentada, conforme segue:
          </p>
          <div>
            <table class="w-full">
              <thead>
                <tr>
                  <td class="border p-2"><b>Nível de Criticidade</b></td>
                  <td class="border p-2"><b>Descrição</b></td>
                  <td class="border p-2"><b>Tempo do Primeiro Retorno</b></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border p-2"><b>Baixo</b></td>
                  <td class="border p-2">
                    Assuntos periféricos que não impactam no funcionamento ou uso de Serviços
                  </td>
                  <td class="border p-2">
                    16 (dezesseis) horas úteis (atendimento e abertura do chamado caso)
                  </td>
                </tr>
                <tr>
                  <td class="border p-2"><b>Médio</b></td>
                  <td class="border p-2">
                    Problemas que afetam uma ou mais funcionalidades de Serviços e/ou o seu uso, mas
                    que não impedem o uso geral de Serviços
                  </td>
                  <td class="border p-2">6 (seis) horas úteis</td>
                </tr>
                <tr>
                  <td class="border p-2"><b>Alto</b></td>
                  <td class="border p-2">
                    Problemas que impeçam ou afetem de maneira substancial o uso geral de Serviços
                  </td>
                  <td class="border p-2">4 (quatro) horas úteis</td>
                </tr>
                <tr>
                  <td class="border p-2"><b>Altíssimo</b></td>
                  <td class="border p-2">
                    Indisponibilidade de Serviços (ou seja, caso a Plataforma esteja fora do ar)
                  </td>
                  <td class="border p-2">2 (duas) horas</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <b>1.6.3.</b> Em caso de problemas técnicos relacionados aos serviços, a MOBCALL
            envidará os melhores esforços para que o acesso seja normalizado no menor prazo
            possível.
          </p>
          <p>
            <b>1.6.4.</b> Caso a MOBCALL necessite de alguma informação da CONTRATANTE para o
            diagnóstico e/ou resolução de falhas nos serviços, a CONTRATANTE compromete-se a
            fornecer prontamente as informações necessárias.
          </p>
          <p>
            <b>1.7.</b> Serviços de Manutenção. A MOBCALL oferecerá serviços de manutenção aos
            serviços ("Manutenção”), incluindo a manutenção preventiva e corretiva, que consistem na
            realização periódica de inspeções, ajustes, substituições técnicas e reparos necessários
            para a segurança, bom funcionamento e estabilidade dos Serviços, em razão de erros de
            lógica, definição, codificação e testes, entre outros.
          </p>
        </div>

        <div class="space-y-4 text-sm pb-10">
          <p class="font-semibold text-gray-800">
            Anexo III – Política de Privacidade e Proteção de Dados Pessoais
          </p>
          <p>
            <b>1.1.</b> Definições. Para efeitos da presente Política de Privacidade e Proteção de
            Dados Pessoais (“Política”), as definições abaixo, quando utilizadas em letra maiúscula,
            no singular ou plural, guardarão os seguintes significados:
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(i)</span> “Contrato”: o Contrato de Licença de Uso de
            Software Como Serviço e Prestação de Serviços celebrado entre a CONTRATANTE e a Mobcall,
            do qual a presente Política é parte integrante e indissociável como Anexo III.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(ii)</span> “Anonimização” (e termos relacionados como
            “Anonimizar” e “Anonimizados”): todo e qualquer meio e processo técnico razoável,
            disponíveis na ocasião do Tratamento de Dados Pessoais, que resulte na não-identificação
            do titular do referido Dado Pessoal. Os dados anonimizados não serão considerados Dados
            Pessoais para os fins da Lei n. 13.709/18, salvo quando o processo de anonimização se
            tratar, na realidade, de uma Pseudonimização.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(iii)</span> “Dados Pessoais”: dados e informações obtidos
            por meios digitais ou não, capazes de identificar ou tornar identificáveis pessoas
            físicas, incluindo dados que possam ser combinados com outras informações para
            identificar um indivíduo e/ou que se relacionem com a identidade, características ou
            comportamento de um indivíduo ou influenciem na maneira como esse indivíduo é tratado ou
            avaliado, incluindo números identificativos, dados locacionais e/ou identificadores
            eletrônicos, tais como cookies, beacons, advertising ids e tecnologias correlatas. Os
            dados referentes aos indivíduos diretamente envolvidos nas atividades de execução do
            objeto do Contrato também estão incluídos na presente classificação de Dados Pessoais.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(iv)</span> “Dados Sensíveis”: Dados Pessoais referentes à
            origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou
            à organização de caráter religioso, filosófico ou político, dado referente à saúde ou à
            vida sexual, dado genético ou biométrico, quando vinculado a um indivíduo, ou qualquer
            dado que, quando combinado com outras informações, possa inferir um dado pessoal
            sensível.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(v)</span> “Incidente”: todo e qualquer Tratamento de
            Dados Pessoais não autorizado, seja acidental ou doloso (dentro ou não de uma mesma
            rede), incluindo, sem limitação, acesso, incidentes de segurança da informação ou
            violação da privacidade e identidade de terceiros para realização de um Tratamento não
            autorizado, e todas as outras formas ilegais de Tratamento dos Dados Pessoais.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(vi)</span> “Pseudonimização” (e termos relacionados como
            “Pseudonimizar” e “Pseudonimizado”): todo e qualquer meio e processo técnico aplicado no
            Tratamento de Dados Pessoais que resulte na não-identificação do Titular do referido
            Dado Pessoal, mas que, por meio de esforços razoáveis ou mediante a utilização exclusiva
            de meios próprios, possa reverter tal processo permitindo que o Titular seja
            identificado ou identificável.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(vii)</span> “Titular”: pessoa natural a quem se refere os
            Dados Pessoais que são objeto de Tratamento no âmbito do Contrato.
          </p>
          <p class="pl-16 relative">
            <span class="absolute left-7">(viii)</span> “Tratamento” (e termos relacionados como
            “Tratar”, “Tratados”): toda e qualquer operação realizada com Dados Pessoais, incluindo
            a coleta, produção, recepção, classificação, utilização, acesso, reprodução,
            transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação,
            avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou
            extração, conforme dispõe a Lei n. 13.709/18 e o Decreto 8.771/2016.
          </p>
          <p>
            <b>1.2.</b> Tratamento. No desenvolvimento de quaisquer atividades relacionadas à
            execução do Contrato, as Partes obrigam-se a cumprir rigorosamente toda legislação
            brasileira aplicável de proteção de Dados Pessoais vigente, em especial as novas
            exigências trazidas pela Lei n. 13.709/2018, responsabilizando-se pelo uso indevido que
            fizer dos Dados Pessoais Tratados no âmbito do Contrato em desacordo com a legislação
            aplicável e/ou com as disposições do Contrato.
          </p>
          <p>
            <b>1.3.</b> Agentes de Tratamento. As Partes concordam que, no âmbito da execução do
            Contrato, a CONTRATANTE atuará como controladora dos Dados Pessoais Tratados, enquanto a
            MOBCALL atuará como operadora dos Dados Pessoais, aplicando-se às Partes, portanto, as
            obrigações e responsabilidades previstas na legislação aplicável no tocante à sua
            atuação.
          </p>
          <p>
            <b>1.4.</b> Finalidade. As Partes somente poderão Tratar os Dados Pessoais
            exclusivamente para as finalidades de otimizar o atendimento realizado aos seus
            clientes, facilitar a integração da sua área de atendimento com outras áreas de negócios
            e aprimorar a sua estratégia de marketing e de vendas, conforme previsto do Contrato, e
            exclusivamente de acordo com as instruções, recomendações e orientações recebidas da
            CONTRATANTE (“Usos Permitidos”).
          </p>
          <p>
            <b>1.5.</b> Utilização dos Dados. A CONTRATANTE declara e garante que (i) não
            reproduzirá e não realizará a captura de tela, todo ou em parte, de Serviços e das suas
            interfaces durante seu uso, salvo para uso relacionado ao objeto do Contrato; (ii) não
            realizará ou tentará realizar coleta de quaisquer dados disponíveis na Plataforma, por
            meio de ferramenta de coleta automatizada, ou por qualquer outro meio que seja,
            especialmente por meio da criação de crawlers e bots no geral; (iii) não criará ou
            utilizará programas que venham a alterar, incluir ou excluir dados contidos na
            Plataforma, se for o caso, ou de qualquer forma interferir no normal funcionamento de
            Serviços; e (iv) não utilizará os dados e informações disponibilizados pela Plataforma
            para constranger, coagir, discriminar ou prejudicar, de qualquer forma, os Titulares dos
            Dados Pessoais e/ou como justificativa para prática de atos que violem ou ameacem
            interesses e direitos de terceiros. A CONTRATANTE reconhece ser a única responsável pelo
            uso que fizer dos dados e informações disponibilizados através de Serviços, reconhecendo
            não haver qualquer vínculo e/ou responsabilidade da MOBCALL relacionada às análises,
            decisões, ações, abstenções e omissões que a CONTRATANTE vier a adotar. A CONTRATANTE
            será a única e exclusiva responsável, por si e por seus Colaboradores, perante s
            Titulares, as autoridades e demais terceiros, quanto à utilização dos dados e
            informações obtidos através de Serviços, respondendo pelas perdas e danos que possam,
            eventualmente, se originar dessa utilização.
          </p>
          <p>
            <b>1.6.</b> Onboarding de Dados. Na hipótese de a CONTRATANTE vir a disponibilizar, por
            meio de Serviços Mobcall, quaisquer Dados Pessoais de sua titularidade e/ou disponíveis
            em seus bancos de dados, por exemplo, as gravações das chamadas, para que tais dados
            sejam analisados e processados por meio de Serviços Mobcall (“Onboarding de Dados”), a
            CONTRATANTE desde já garante, perante a MOBCALL e terceiros, que: (i) todos os
            procedimentos de Onboarding de Dados serão realizados a único e exclusivo critério da
            CONTRATANTE, e os Dados Pessoais constantes serão coletados e compartilhados de acordo
            com uma base legal válida prevista na legislação aplicável; (ii) é a controladora dos
            Dados Pessoais disponibilizados, reconhecendo a MOBCALL, única e exclusivamente, como
            operadora desses Dados Pessoais, nos termos da legislação aplicável; (iii) os
            procedimentos de Onboarding de Dados serão realizados de acordo com as melhores práticas
            de privacidade, proteção de dados, confidencialidade e requisitos de segurança de
            informações previstos na legislação aplicável; e (iv) todos os documentos, imagens,
            informações e dados, incluindo Dados Pessoais, compartilhados com a Plataforma cumprem
            com todas as leis aplicáveis, estatutos, instrumentos legais, contratos, regulamentos de
            proteção e privacidade de Dados Pessoais.
          </p>
          <p>
            <b>1.6.1.</b> Na hipótese de a CONTRATANTE solicitar o processamento de quaisquer dados
            que possam ser considerados Dados Sensíveis, a CONTRATANTE responsabiliza-se
            integralmente pelo uso dos Dados Sensíveis coletados e compartilhados com a Plataforma,
            bem como por todo e qualquer acesso realizados destes, isentando a MOBCALL de qualquer
            responsabilidade pelo processamento de tais Dados Sensíveis.
          </p>
          <p>
            <b>1.6.2.</b> A CONTRATANTE será a única responsável pelos dados e as informações que
            transmitir à Plataforma ou através dela, incluindo Dados Pessoais, bem como por
            eventuais ações, omissões, falhas ou erros cometidos pela CONTRATANTE no acesso ou no
            compartilhamento dos Dados Pessoais com a MOBCALL, bem como por perdas e danos
            decorrentes do uso, direto ou indireto, por parte da CONTRATANTE, dos Dados Pessoais
            tratados e processados pela Plataforma, isentando a MOBCALL e mantendo-a indene de
            quaisquer responsabilidades nesse sentido, incluindo pelos conteúdos gerados pela
            CONTRATANTE e as decisões tomadas pela CONTRATANTE.
          </p>
          <p>
            <b>1.7.</b> Base Legal. As Partes garantem que todo e qualquer Tratamento de Dados
            Pessoais realizado no âmbito do Contrato será feito sempre utilizando uma base legal
            válida, legítima e adequada ao Tratamento, na forma autorizada pela legislação
            aplicável,, respeitadas as expectativas e direitos do titular do Dado Pessoal.
          </p>
          <p>
            <b>1.8. Transparência.</b> Sempre que necessário, as Partes concordam expressamente em
            incluir, em suas políticas de privacidade ou outros instrumentos firmados com os
            Titulares dos Dados Pessoais, referências claras, destacadas das demais cláusulas
            contratuais, quanto aos Usos Permitidos, especialmente quanto aos Dados Sensíveis,
            informando ao Titular, ainda, sobre a finalidade para a qual o Dado Pessoal é utilizado,
            com quem ele é compartilhado (incluindo eventual transferência internacional), as
            medidas de segurança aplicadas, os prazos de retenção do Dado Pessoal e os direitos do
            Titular sobre seus Dados Pessoais.
          </p>
          <p>
            <b>1.9.</b> Propriedade. As Partes declaram estar cientes e de acordo que todos os Dados
            Pessoais Tratados em decorrência da execução do objeto do Contrato serão considerados de
            propriedade exclusiva da Parte que os detinha antes da assinatura do Contrato ou do seu
            compartilhamento com a Parte contrária.
          </p>
          <p>
            <b>1.10.</b> Segurança. As Partes garantem que cumprirão com todas as políticas, regras
            e orientações de segurança da informação, em especial no que concerne aos Dados
            Pessoais, incluindo questões relativas a armazenamento, criptografia, controles de
            acesso (dupla autenticação, manutenção de inventário detalhado) e serviços de firewalls,
            a fim de protegê-los contra Incidentes, devendo adotar medidas para garantir a adequada
            segurança contra os riscos apresentados em decorrência da natureza dos Dados Pessoais.
            As Partes se obrigam a efetuar a gestão de vulnerabilidade de seus sistemas realizando
            testes periódicos para identificação e imediata correção de eventuais vulnerabilidades
            que venham a ser identificadas.
          </p>
          <p>
            <b>1.10.1.</b> Caso as Partes tenham conhecimento da ocorrência ou mera suspeita de um
            Incidente ou Tratamento não autorizado de Dados Pessoais, elas deverão notificar a Parte
            contrária por escrito e de forma detalhada sobre: (i) a ocorrência ou suspeita de
            qualquer Incidente relacionado a Dados Pessoais, com a apresentação de todas as
            informações e detalhes disponíveis sobre tal Incidente, incluindo a identificação de
            quais Dados Pessoais foram afetados e as medidas tomadas (e aquelas em vias de serem
            tomadas) para mitigar os efeitos de tal Incidente; (ii) a existência de qualquer
            instrução fornecida pela Parte contrária que no contexto do Tratamento dos Dados
            Pessoais contrarie a legislação aplicável; e (iii) qualquer fato ou situação específica
            que razoavelmente a impeça de cumprir qualquer de suas obrigações previstas do Contrato
            e/ou na legislação aplicável no contexto do Tratamento dos Dados Pessoais. Todas as
            notificações previstas nessa cláusula deverão ser enviadas imediatamente e sem atraso
            injustificado à outra Parte, em prazo não superior a 24 (vinte e quatro) horas contadas
            da ciência do Incidente ou Tratamento não autorizado.
          </p>
          <p>
            <b>1.11.</b> Exercício de Direitos. Caso algum titular dos Dados Pessoais Tratados no
            âmbito do Contrato faça alguma requisição no exercício de seus direitos previstos nas
            legislações pertinentes de proteção de Dados Pessoais, como, por exemplo, mas sem
            limitação, solicite a alteração, atualização, correção, acesso, portabilidade ou
            exclusão de seus Dados Pessoais, a Parte que receber tal solicitação deverá comunicar
            tal fato imediatamente à Parte contrária e, mediante autorização escrita da STIX,
            proceder ao atendimento da requisição feita pelo Titular dos Dados Pessoais de forma
            gratuita e sem demora injustificada.
          </p>
          <p>
            <b>1.12.</b> Exclusão dos Dados. As Partes reconhecem que após atingida a finalidade do
            Tratamento quanto aos Usos Permitidos e/ou terminada a relação contratual entre as
            Partes, as Partes deverão destruir os Dados Pessoais compartilhados ou garantir sua
            efetiva Anonimização, salvo se, por obrigação legal, tiverem que mantê-los, tais como
            obrigações oriundas de regulações setoriais. Ainda, os Dados Pessoais serão excluídos
            dos sistemas das Partes, mediante requisição dos Titulares dos respectivos Dados
            Pessoais, salvo se houver qualquer base legal ou contratual para a sua manutenção, como
            eventual obrigação legal de retenção dos Dados Pessoais ou necessidade de preservação
            destes para resguardo de direitos e interesses legítimos das Partes. Não havendo
            qualquer motivo que justifique a manutenção dos Dados Pessoais, as Partes se obrigam a
            efetuar expurgo dos Dados Pessoais de forma segura, inclusive de backup.
          </p>
          <p>
            <b>1.12.1.</b> Em conformidade com as melhores práticas de mercado, as Partes concordam
            em cumprir com a legislação aplicável, informando aos Titulares dos Dados Pessoais,
            sempre que necessário, sobre o procedimento detalhado para desativar o Tratamento de
            seus Dados Pessoais, assim como para solicitar sua exclusão, disponibilizando, por
            exemplo e se cabível, links que ofereçam tais possibilidades.
          </p>
          <p>
            <b>1.13.</b> Confidencialidade. As disposições sobre confidencialidade previstas no
            Contrato aplicam-se às Partes no tocante aos Dados Pessoais, bem como a todos os seus
            Colaboradores envolvidos na execução do Contrato, permanecendo esta obrigação em vigor
            mesmo após o término da sua vigência. Caso a divulgação dos Dados Pessoais seja exigida
            por requisição de autoridades administrativas competentes ou por determinação judicial,
            a Parte que receber tal requisição deverá notificar previamente à Parte contrária acerca
            da existência e do conteúdo da ordem/requisição correspondente, em tempo razoável para
            que esta possa, caso deseje, apresentar suas medidas ou contrarrazões perante o juízo ou
            autoridade competente.
          </p>
          <p>
            <b>1.14.</b> Compartilhamento. Cada Parte declara e reconhece que, em razão da natureza
            dos Dados Pessoais, somente será autorizada a comunicação e/ou o uso compartilhado de
            Dados Pessoais para as finalidades previstas no Contrato. Qualquer compartilhamento de
            Dados Pessoais recebidos da Parte contrária para finalidade diversa do previsto no
            Contrato somente poderá ser realizado com a prévia e expressa autorização da Parte que
            os divulgar, principalmente se houver objetivo de obter vantagem econômica. Sempre que
            possível, os Dados Pessoais serão Tratados e compartilhados de forma Anonimizada ou
            Pseudonimizada, preservando a identidade das Partes e dos Titulares dos Dados Pessoais.
          </p>
          <p>
            <b>1.15.</b> Responsabilidade. As Partes serão responsáveis, por si e por seus
            Colaboradores, pelo Tratamento de Dados Pessoais por ela realizado no contexto do
            Contrato e da relação entre as Partes, mantendo a Parte contrária indene de quaisquer
            responsabilidades, danos ou prejuízos, diretos e indiretos, decorrentes de qualquer
            operação de Tratamento de Dados Pessoais realizada em desacordo com do Contrato e/ou a
            legislação brasileira aplicável.
          </p>
          <p>
            <b>1.16.</b> Dano e indenização. Havendo dano ou prejuízo suportado por uma Parte em
            razão de qualquer descumprimento de obrigações legais ou contratuais relacionadas à
            proteção dos Dados Pessoais por culpa, dolo, negligência ou imprudência da Parte
            contrária ou de seus Colaboradores, incluindo penalidades administrativas e condenações
            em processos judiciais ou arbitrais, deverá a Parte prejudicada ser indenizada no valor
            integral das perdas e danos, nos termos da cláusula 12.15 do Contrato.
          </p>
        </div>
      </div>
    </div>
  </div>

  <UiLoading :show="loading" />
</template>

<style>
/* CSS para esconder elementos durante a exportação para PDF */
.print-hide {
  display: none !important;
}
</style>
