<script setup>
import { useRoute, RouterLink } from "vue-router";
import router from "@/router";
import { UiGravatar } from "@/components";

const route = useRoute();

const logoPath = "/logomob.png";

const props = defineProps({
  navigation: Array,
  userName: String,
  userEmail: String,
});

const isActive = (item) => {
  if (item.route === route.name) {
    return true;
  } else {
    return false;
  }
};

const logout = () => {
  localStorage.removeItem("token");
  router.push({ name: "Login" });
};
</script>

<template>
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
    <div
      class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
    >
      <div class="flex h-16 shrink-0 items-center">
        <img class="h-8 w-auto" :src="logoPath" alt="Logo Mobcall" />
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li v-for="item in props.navigation" :key="item.name">
                <router-link
                  :to="{ name: item.route }"
                  :class="[
                    isActive(item)
                      ? 'bg-gray-50 text-mob-600'
                      : 'text-gray-700 hover:text-mob-600 hover:bg-gray-50',
                    'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                  ]"
                >
                  <i
                    :class="[
                      item.icon,
                      isActive(item)
                        ? 'text-mob-600'
                        : 'text-gray-400 group-hover:text-mob-600',
                      'bx bx-sm',
                    ]"
                  />
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </li>

          <li class="-mx-6 mt-auto">
            <button
              @click="logout()"
              class="group flex items-center w-full gap-x-4 px-6 py-3 rounded-md text-sm leading-6 font-semibold text-gray-600 hover:text-mob-600 hover:bg-gray-50"
            >
              <i
                class="bx bx-sm bx-log-out-circle text-gray-600 group-hover:text-mob-600"
              ></i>
              Sair
            </button>
            <div
              class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 bg-gray-100/50 border-t border-gray-200"
            >
              <UiGravatar
                :userName="props.userName"
                :userEmail="props.userEmail"
              />
              <span class="sr-only">Seu Perfil</span>
              <span aria-hidden="true">{{ props.userName }}</span>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
