<script setup>
import { ref } from "vue";
import { useRoute, RouterLink } from "vue-router";
import router from "@/router";
import { UiGravatar } from "@/components";

const route = useRoute();

const logoPath = "/logomob.png";

import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon, Bars3Icon } from "@heroicons/vue/24/outline";

const sidebarOpen = ref(false);

const props = defineProps({
  navigation: Array,
  userName: String,
  userEmail: String,
});

const isActive = (item) => {
  if (item.route === route.name) {
    return true;
  } else {
    return false;
  }
};

const logout = () => {
  localStorage.removeItem("token");
  router.push({ name: "Login" });
};
</script>

<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog
      as="div"
      class="relative z-50 lg:hidden"
      @close="sidebarOpen = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div
                class="absolute left-full top-0 flex w-16 justify-center pt-5"
              >
                <button
                  type="button"
                  class="-m-2.5 p-2.5"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div
              class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2"
            >
              <div class="flex h-16 shrink-0 items-center">
                <img class="h-8 w-auto" :src="logoPath" alt="Logo Mobcall" />
              </div>
              <nav class="flex flex-1 flex-col">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" class="-mx-2 space-y-1">
                      <li v-for="item in props.navigation" :key="item.name">
                        <router-link
                          :to="{ name: item.route }"
                          :class="[
                            isActive(item)
                              ? 'bg-gray-50 text-mob-600'
                              : 'text-gray-700 hover:text-mob-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          ]"
                          @click="sidebarOpen = false"
                        >
                          <i
                            :class="[
                              item.icon,
                              isActive(item)
                                ? 'text-mob-600'
                                : 'text-gray-400 group-hover:text-mob-600',
                              'bx bx-sm',
                            ]"
                          />
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </li>

                  <li class="-mx-6 mt-auto">
                    <button
                      @click="logout()"
                      class="group flex items-center w-full gap-x-4 px-6 py-3 rounded-md text-sm leading-6 font-semibold text-gray-600 hover:text-mob-600 hover:bg-gray-50"
                    >
                      <i
                        class="bx bx-sm bx-log-out-circle text-gray-600 group-hover:text-mob-600"
                      ></i>
                      Sair
                    </button>
                    <div
                      class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 bg-gray-100/50 border-t border-gray-200"
                    >
                      <UiGravatar
                        :userName="props.userName"
                        :userEmail="props.userEmail"
                      />
                      <span class="sr-only">Seu Perfil</span>
                      <span aria-hidden="true">{{ props.userName }}</span>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <div
    class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden"
  >
    <button
      type="button"
      class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
      @click="sidebarOpen = true"
    >
      <span class="sr-only">Open sidebar</span>
      <Bars3Icon class="h-6 w-6" aria-hidden="true" />
    </button>
  </div>
</template>
