<script setup>
const props = defineProps({
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: [String, Boolean],
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue", "blur"]);
function handleInput(event) {
  const value = (event.target).value;
  emit("update:modelValue", value);
}
</script>

<template>
  <div>
    <label v-if="label" class="block font-medium text-gray-600 mb-1">
      {{ label }}
    </label>
    <input
      :type="type"
      :value="props.modelValue"
      :name="name"
      :placeholder="placeholder"
      @input="handleInput"
      @blur="emit('blur', $event)"
      class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 px-3 py-2"
    />
  </div>
</template>
