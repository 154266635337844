<script setup>
import { useRoute, RouterLink } from "vue-router";

const route = useRoute();

</script>

<template>
  <div>
    <h1 class="font-semibold text-xl text-gray-900">{{ route.meta.title }}</h1>
  </div>

  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="min-w-full py-2 align-middle sm:px-6 lg:px-8 grid grid-cols-3 gap-6">
        
        <router-link :to="{ name: 'PageListOs' }" class="shadow ring-1 ring-black ring-opacity-5 rounded-md px-4 py-5 bg-white flex items-center">
          <i class="bx bx-lg bx-spreadsheet mr-3 text-mob-600"></i>
          <div>
            <h3 class="font-semibold text-gray-700">Ordens de Serviços criadas</h3>
            <p class="text-sm">Ver lista</p>
          </div>
        </router-link>

        <router-link :to="{ name: 'PageCreateOs' }" class="shadow ring-1 ring-black ring-opacity-5 rounded-md px-4 py-5 bg-white flex items-center">
          <i class="bx bx-lg bxs-file-plus mr-3 text-mob-600"></i>
          <div>
            <h3 class="font-semibold text-gray-700">Criar uma Ordem de Serviço</h3>
            <p class="text-sm">Nova OS</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>