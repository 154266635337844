<script setup>
import { ref } from "vue";
import router from "@/router";
import axios from "axios";
import { useToast } from "vue-toastification";
import { UiLoading } from "@/components";

const toast = useToast();

const loading = ref(false);

const ds_mail = ref();
const ds_senh = ref();

const login = async () => {
  const url = "https://app.mobcall.com/api/auth";
  const config = {
    method: "POST",
    url,
    data: {
      ds_mail: ds_mail.value,
      ds_senh: ds_senh.value,
    },
  };

  const regex = /@(mobcall\.com|reach\.com\.br)$/;

  loading.value = !loading.value;

  try {
    if (regex.test(ds_mail.value)) {
      const response = await axios(config);
      localStorage.setItem("userName", response.data.ds_nome);
      localStorage.setItem("userEmail", response.data.ds_mail);
      localStorage.setItem("token", response.data.token);
      router.push({ name: "Home" });
    } else {
      loading.value = !loading.value;
      toast.error("Você não tem permissões para acessar.", {
        timeout: 3000,
      });
    }
  } catch (error) {
    loading.value = !loading.value;
    console.log(error);
    toast.error("Verifique os dados e tente novamente.", {
      timeout: 3000,
    });
  }
};
</script>

<template>
  <div class="w-screen flex justify-center mt-24">
    <form
      @submit.prevent="login"
      class="bg-white p-12 shadow-lg rounded-lg flex flex-col items-center justify-center w-[400px] space-y-3"
    >
      <img class="w-40" src="/logomob.png" alt="Logo Mobcall" />
      <h1 class="font-semibold text-2xl pb-4 text-gray-700">
        Gerador de Propostas
      </h1>

      <div class="flex flex-col space-y-1 w-full">
        <label for="ds_mail" class="font-semibold">Login</label>
        <input
          type="email"
          name="ds_mail"
          v-model="ds_mail"
          required="required"
          class="block rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 text-lg px-3 py-2"
        />
      </div>

      <div class="flex flex-col space-y-1 w-full">
        <label for="ds_senh" class="font-semibold">Senha</label>
        <input
          type="password"
          name="ds_senh"
          v-model="ds_senh"
          required="required"
          class="block rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mob-600 text-lg px-3 py-2"
        />
      </div>

      <div class="pt-4 w-full">
        <button
          type="submit"
          class="w-full rounded-md bg-mob-600 py-3 font-semibold text-white shadow-sm hover:bg-mob-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mob-600 uppercase"
        >
          Fazer Login
        </button>
      </div>
    </form>
  </div>

  <UiLoading :show="loading" />
</template>
