import axios from "axios";
import router from "@/router";

let baseURL = "";

switch (process.env.NODE_ENV) {
  case "production":
    baseURL = "/webhook/gerador-propostas";
    break;
  case "qa":
    baseURL = "/webhook/gerador-propostas-qa";
    break;
  default:
    baseURL = "https://services-01.mobcall.dev/webhook/gerador-propostas-qa";
    break;
}

const n8nToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";

const instance = axios.create({
  baseURL,
  timeout: 40 * 1000,
});

instance.interceptors.request.use(
  (config) => {
    const tokenSession = localStorage.getItem("token");

    config.headers["Content-Type"] = config.headers["Content-Type"]  || "application/json";
    config.headers["Authorization"] = n8nToken;
    config.headers["tokenSession"] = "Bearer " + tokenSession;

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      router.push({ name: "Login" });
    } else {
      return Promise.reject(error);
    }
  },
);

export default instance;
