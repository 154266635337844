import { createApp } from 'vue'
import router from './router/index.js'
import App from './App.vue'
import money from 'v-money3'
import VueTheMask from 'vue-the-mask'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import './index.css'

const app = createApp(App)

app.use(money)
app.use(VueTheMask)
app.use(router)
app.use(Toast)

app.mount('#app')