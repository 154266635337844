<script setup>
import { ref, onMounted } from "vue";
import { useRoute, RouterLink } from "vue-router";
import axios from "@/axios";
import "moment/dist/locale/pt-br";
import moment from "moment";
import { UiLoading } from "@/components";

const route = useRoute();

const propostas = ref([]);

const loading = ref(false);

const listOS = async () => {
  loading.value = !loading.value;

  try {
    const { data } = await axios({
      method: "GET",
    });

    propostas.value = data.rows;

    loading.value = !loading.value;
  } catch (error) {
    console.error(error);

    loading.value = !loading.value;
  }
};

const inactiveOS = async (id) => {
  const config = {
    method: "POST",
    url: "/proposta/inactive",
    data: {
      proposta_id: id,
    },
  };

  try {
    await axios(config);
    listOS();
  } catch (error) {
    loading.value = !loading.value;
    console.error(error);
  }
};

const activeOS = async (id) => {
  const config = {
    method: "POST",
    url: "/proposta/active",
    data: {
      proposta_id: id,
    },
  };

  try {
    await axios(config);
    listOS();
  } catch (error) {
    loading.value = !loading.value;
    console.error(error);
  }
};

const removeOS = async (id) => {
  const config = {
    method: "POST",
    url: "/proposta/delete",
    data: {
      proposta_id: id,
    },
  };

  try {
    if (window.confirm("Você tem certeza que deseja excluir este registro?")) {
      await axios(config);
      listOS();
    }
  } catch (error) {
    loading.value = !loading.value;
    console.error(error);
  }
};

async function main() {
  try {
    await listOS();
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  main();
});
</script>

<template>
  <div>
    <h1 class="font-semibold text-xl text-gray-900">{{ route.meta.title }}</h1>
  </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div v-if="propostas.length > 0" class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  OS
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Empresa
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Criado por
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Criado em
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="proposta in propostas" :key="proposta.id">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  000{{ proposta.id }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ proposta.company_name }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ proposta.created_by }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ moment(proposta.created_at).format("DD MMMM YYYY") }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <span :class="[
                    proposta.status === 1
                      ? 'text-green-700 bg-green-100'
                      : 'text-red-700 bg-red-100',
                    'ml-1 font-semibold px-2 py-0 ring-1 ring-black ring-opacity-5 rounded',
                  ]">
                    {{
                      proposta.status === 1
                      ? "ativa"
                      : proposta.status === 2
                        ? "excluida"
                        : "inativa"
                    }}</span>
                </td>
                <td
                  class="relative whitespace-nowrap p-4 text-sm font-medium sm:pr-6 space-x-3 flex items-center justify-end">
                  <router-link :to="{ name: 'PagePdfOs', params: { id: proposta.id } }" title="Gerar PDF"
                    class="text-red-700 hover:text-red-600"><i class="bx bxs-file-pdf bx-sm" /></router-link>
                  <router-link :to="{
                    name: 'PageDuplicateOs',
                    params: { id: proposta.id },
                  }" title="Duplicar OS" class="text-gray-400 hover:text-mob-600"><i
                      class="bx bxs-duplicate bx-sm" /></router-link>
                  <button v-if="proposta.status === 1" @click="inactiveOS(proposta.id)" title="Inativar OS"
                    class="text-gray-400 hover:text-gray-600">
                    <i class="bx bxs-x-circle bx-sm" />
                  </button>
                  <button v-else @click="activeOS(proposta.id)" class="text-gray-400 hover:text-green-600"
                    title="Reativar OS">
                    <i class="bx bxs-check-circle bx-sm" />
                  </button>
                  <button v-if="false" @click="removeOS(proposta.id)" class="text-gray-400 hover:text-red-600"
                    title="Excluir OS">
                    <i class="bx bx-trash bx-sm" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-else class="flex justify-center">
          <div class="flex items-center mt-40">
            <i class='bx bx-sm bx-file-blank mr-1'></i>
            <h3 class="font-semibold text-xl">Nenhum registro encontrado.</h3>
          </div>
        </div>
      </div>
    </div>
  </div>

<UiLoading :show="loading" />
</template>
