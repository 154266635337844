<script setup>
import { RouterView } from "vue-router";
import { SidebarDesktop, SidebarMobile } from "@/views/templates/components";

const navigation = [
  { name: "Início", route: "Home", icon: "bx-home-alt" },
  { name: "Ordens de Serviço", route: "PageListOs", icon: "bx-spreadsheet" },
  { name: "Nova Ordem de Serviço", route: "PageCreateOs", icon: "bxs-file-plus" },
];

const userName = localStorage.getItem("userName");
const userEmail = localStorage.getItem("userEmail");

</script>

<template>
  <div>
    <!-- Sidebar for mobile -->
    <sidebar-mobile :navigation="navigation" :userName="userName" :userEmail="userEmail" />

    <!-- Sidebar for desktop -->
    <sidebar-desktop :navigation="navigation" :userName="userName" :userEmail="userEmail" />

    <!-- Content -->
    <main class="py-10 lg:pl-72">
      <div class="px-4 sm:px-6 lg:px-8">
        <router-view :key="$route.fullPath" />
      </div>
    </main>
  </div>
</template>
