const onlyNumbers = (value) => {
  if (value) return value.replace(/[^0-9]/g, "");
};

const getLoggedUser = async (users, formData) => {
  const userEmail = localStorage.getItem("userEmail");
  const filteredData = users.filter((item) => item.email === userEmail);
  const id = filteredData.map((item) => item.id);
  formData.accountable = id[0];
};

export { getLoggedUser, onlyNumbers };
