import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import AppTemplate from '@/views/templates/AppTemplate.vue'
import HomePage from '@/views/pages/Home.vue'
import PageListOs from '@/views/pages/os/PageListOs.vue'
import PageFormOs from '@/views/pages/os/PageFormOs.vue'
import PagePdfOs from '@/views/pages/os/PagePdfOs.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Gerador de Propostas Mobcall' }
  },
  {
    name: 'AppTemplate',
    component: AppTemplate,
    path: '/',
    children: [
      {
        name: 'Home',
        path: '',
        component: HomePage,
        meta: { title: 'Início' }
      },
      {
        name: 'PageListOs',
        path: '/os/list',
        component: PageListOs,
        meta: { title: 'Ordens de Serviço' }
      },
      {
        name: 'PageCreateOs',
        path: '/os/create',
        component: PageFormOs,
        meta: { title: 'Nova Ordem de Serviço' }
      },
      {
        name: 'PageDuplicateOs',
        path: '/os/duplicate/:id',
        component: PageFormOs,
        meta: { title: 'Duplicar Ordem de Serviço' }
      },
      {
        name: 'PagePdfOs',
        path: '/os/:id',
        component: PagePdfOs,
        meta: { title: 'PDF da Ordem de Serviço' }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const noCheck = ['/login']
  const token = localStorage.getItem('token')

  if (token === null) {
    if (noCheck.indexOf(to.path) != -1) {
      next()
    }
    next({ name: 'Login' })
  } else {
    next()
  }

  document.title = to.meta?.title + ' - Mobcall' ?? 'Mobcall'
})

export default router
